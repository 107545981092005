import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios from "axios";
import { API } from "../../constants/API";

interface isbnObj {
  _id?: string;
  ISBN?: string;
  FULL_TITLE?: string;
}

interface isbnsData {
  isbnsString:string;
  list:isbnObj[];
  matchedIsbns : isbnObj[];
  unMatchedIsbns : string[];
  status ?: number;
  message ?:string;
  isUploadLoading ?: boolean;
}

interface budgetReq {
  account_name : string,
  account_number : number
  approved_by : number,
  approver : string[],
  coopType : string, 
  deadline : string,
  description : string, 
  endDate : string, 
  isbn : number[],
  notes : string,
  required_amount_budget : number,
  required_approval : boolean,
  startDate : string,
  status : number,
  title : string[],
  _id : string,
  approved_and_declined_comment?:string;
  approverName?:string,
  claim_number:string;
  approverData?:{
    first_name:string
  },
  createdAt?:Date | null;
  updatedAt?:Date | null;
  canApprove?:boolean
  
}

interface budgets {
  budgetRequests ?: budgetReq[];
  status ?: number;
  message ?: string;
  page:number;
  limit:number;
  totalPages?:number;
  totalDocuments?:number;
}

interface budget {
  status ?: number;
  message ?: string;
}

interface accountObj {
  _id?:string;
  Account_name?:string;
  Account_number?:string;
}

interface account {
  accounts ?: accountObj[];
  status ?: number;
  message ?: string;
}

interface singleBudgetReq {
  budget ?: budgetReq;
  status ?: number;
  message ?: string;
}

interface isbnObj {
  isbn ?: string;
  title ?: string;
}

interface budgetObj {
  status : number;
  message : string;
  remainingBudget: number;
}

interface newBudgetState {
  message?: string;
  err?: string;
  status?: string;
  accounts?: account;
  statusCode?: number;
  isbns?:isbnsData;
  addBudget?:budget,
  budgetRequests?:budgets;
  selectedBudgetRow?:singleBudgetReq;
  selectedIsbnRow?:isbnObj[];
  account_budget:budgetObj;
  isLoading?: boolean;
  budgetsTab:string;
}

const initialState: newBudgetState = {
  accounts: {
    accounts : [],
    status : 0,
    message : "",
  },
  err: "",
  status: "",
  message: "",
  statusCode: 0,
  isbns:{
    isbnsString:"",
    list:[],
    matchedIsbns:[],
    unMatchedIsbns:[],
    isUploadLoading:false
  },
  budgetRequests : {
    budgetRequests : [],
    page:1,
    limit:10,
    status:0,
    message:"",
    totalPages:1,
    totalDocuments:0,
    
  },
  addBudget:{
    status : 0,
    message : ""
  },
  selectedBudgetRow:{
      budget:
        {
        account_name:"",
        account_number:0,
        approved_by:0,
        approver:[],
        coopType:"",
        deadline:"",
        description:"",
        endDate:"",
        isbn:[],
        notes:"",
        required_amount_budget:0,
        required_approval:false,
        startDate:"",
        status:0,
        title:[],
        
        _id:"",
        claim_number:"",
        approverData:{
          first_name:""
        },
        canApprove:false
      },
      status:0,
      message:""
    },
    selectedIsbnRow:[],
    account_budget:{
      status:0,
      message:"",
      remainingBudget:0,
    },
    isLoading: false, 
    budgetsTab:"all"
    
};

interface approverDataObj {
  _id:string,
  approverId?:string;
  isUploadLoading ?: boolean;
}

interface fetchAccountObj {
  limit?:number;
  currentPage?:number;
  selectedAccount_id?:string;
  account_num?:number | null | string;
  account_name?:string | null | number;
}

export const fetchAccounts = createAsyncThunk(
  "budget/fetchAccounts",
  async (data:fetchAccountObj, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.FETCHACCOUNTS,data);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

export const fetchIsbnsByCsv = createAsyncThunk(
  "budget/fetchIsbnsByCsv",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.FETCHISBNS,data);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

export const fetchIsbnByCommaString = createAsyncThunk(
  "budget/fetchIsbnsByCommaSeparated",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.FETCHISBNBYCOMMASEPARATED,data);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

export const addNewbudgetReq = createAsyncThunk(
  "budget/add_budget",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.ADDNEWBUDGETREQUEST,data);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

export const fetchAllBudgetReqs = createAsyncThunk(
  "budget/get_all_budgets",
  async (data : any, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.GETALLBUDGETS,data);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

export const fetchSelectedBudgetReq = createAsyncThunk(
  "budget/get_budget_by_id",
  async (data:approverDataObj, { rejectWithValue }) => {
    try {
      const url = `${API.GETBUDGETBYID}/${data._id}/${data.approverId?.length ? `${data.approverId}`: "null" }`
      const response = await axios.get(url);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

export const fetchIsbnList = createAsyncThunk(
  "budget/isbn_list",
  async (data : any , { rejectWithValue }) => {
    try {
      const response = await axios.post(API.FETCHISBNSLIST,data);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

export const checkAccountBudget = createAsyncThunk(
  "budget/check-account-budget",
  async (data : any , { rejectWithValue }) => {
    try {
      const response = await axios.post(API.CHECKACCOUNTBUDGET,data);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

export const newBudgetSlice = createSlice({
  name: "budget",
  initialState,
  reducers: {
    uploadLoading: (state) => {
      state.isLoading = !state.isLoading
    },
    emptyBudgetReq : (state) => {
      state.accounts = initialState.accounts;
      state.budgetRequests = initialState.budgetRequests;
      state.addBudget = initialState.addBudget;
      state.isbns = initialState.isbns;
      state.err = initialState.err;
      state.message = initialState.message;
      state.status = initialState.status;
      state.statusCode = initialState.statusCode;
      state.account_budget = initialState.account_budget;
      state.selectedIsbnRow = initialState.selectedIsbnRow;
      state.isLoading = initialState.isLoading;
    },
    emptySelectedBudgetReq : (state) => {
      state.selectedBudgetRow = initialState.selectedBudgetRow;
    },
    emptySelectedIsbn : (state) => {
      state.selectedIsbnRow = initialState.selectedIsbnRow;
    },
    selectIsbnDataRow : (state, action : any) =>{

      const isbnArray = action.payload.isbn.map((row : any ,index:any) => {return ({isbn : row, title :action.payload.title[index]}) })
      console.log(isbnArray)
      state.selectedIsbnRow = isbnArray
      // state.selectedIsbnRow.title = action.payload.title
    },
    emptyMatchedIsbns : (state) => {
      if(state.isbns){
        state.isbns.matchedIsbns = initialState.isbns?.matchedIsbns || [];
        state.isbns.isUploadLoading = false;
    }
    },
    emptyUnMatchedIsbns : (state) => {
      if(state.isbns){
        state.isbns.unMatchedIsbns = [];
        state.isbns.isUploadLoading = false
      }
    },
    setIsbnString : (state, action) => {
      if(state.isbns)
        state.isbns.isbnsString = action.payload; 
    },
    emptyIsbnsString : (state) => {
      if(state.isbns)
        state.isbns.isbnsString = ""
    },
    UpdatedBudgetStatus: (state, action) => {
      if (state.selectedBudgetRow?.budget) {
        state.selectedBudgetRow.budget.status = action.payload;
      } 

      if(state.budgetRequests?.budgetRequests){
        state.budgetRequests.budgetRequests.map((data,index)=>{
          if(data._id=== state.selectedBudgetRow?.budget?._id){
            if(state.budgetRequests?.budgetRequests){
              state.budgetRequests.budgetRequests.splice(index,1)
              if(state.budgetsTab === "all")
                state.budgetRequests.budgetRequests.unshift(state.selectedBudgetRow.budget)
            }
 
          }
        })
      }
    },
    updateComment: (state, action) => {
      if (state.selectedBudgetRow?.budget) {
        state.selectedBudgetRow.budget.approved_and_declined_comment = action.payload;
      }
    },
    emptyAccountBudget:(state) => {
      if(state.account_budget){
        state.account_budget.status = initialState.account_budget?.status;
        state.account_budget.message = initialState.account_budget?.message;
    }},
    updateUploadLoading:(state) => {
      if(state.isbns) state.isbns.isUploadLoading = !state.isbns.isUploadLoading;
    },
    updateTab:(state,action) => {
      state.budgetsTab = action.payload
    },
    emptyAddBudgetStatus : (state) => {
      if(state.addBudget){
      state.addBudget.status = initialState.statusCode;
      state.addBudget.message = initialState.message;
    }
    }
  },
  extraReducers: (builder) => {
 

    builder
      .addCase(fetchAccounts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAccounts.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "succeeded";
        if(state.accounts?.accounts){
          const filteredAccounts = action.payload.data.filter((account:any) => !state.accounts?.accounts?.map(acc => acc._id).includes(account._id))
          state.accounts.accounts.push(...filteredAccounts)
          state.accounts.status = action.payload.status ?? 0;
          state.accounts.message = action.payload.data?.message ?? "";
        }
    })
      .addCase(fetchAccounts.rejected, (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if(state.accounts){
          state.accounts.status = action.payload.status;
          state.accounts.message = action.payload.data?.message ?? "";
        }
      });


      // fetch isbns by csv
    builder
      .addCase(fetchIsbnsByCsv.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchIsbnsByCsv.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "succeeded";
        if(state.isbns){
            // update isbn list if match isbns not inside
            const isbnListString = state.isbns.list.map((isbn : any) => isbn.ISBN);
            const matchIsbns = action.payload.data?.matchedBooks.map((isbn : any) => isbn.ISBN);
            
            const filtered = action.payload.data?.matchedBooks.filter((isbn : any) => !isbnListString.includes(isbn.ISBN))

            if(action.payload.data?.matchedBooks.length === filtered.length) {
              state.isbns.list.push(...(action.payload.data?.matchedBooks))
            }
            else if(action.payload.data?.matchedBooks.length != filtered.length){
              state.isbns.list.push(...(filtered))
            }
            else if(!filtered.length){
              const filteredIsbns = state.isbns.list.filter(isbn => !matchIsbns.includes(isbn.ISBN))
              state.isbns.list = filteredIsbns;
            }

          state.isbns.unMatchedIsbns = action.payload.data?.unmatchedISBNs ?? [];
          state.isbns.matchedIsbns = action.payload.data?.matchedBooks ?? []
          state.isbns.status = action.payload?.status ?? 0;
          state.isbns.message = action.payload.data?.message ?? "";
        }
      })
      .addCase(fetchIsbnsByCsv.rejected, (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if(state.isbns){
          state.isbns.status = action.payload?.status ?? 0;
          state.isbns.message = action.payload.data?.message ?? "";
        }
      });


      // add budget request
    builder
      .addCase(addNewbudgetReq.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(addNewbudgetReq.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "succeeded";
        if(state.addBudget)
        {  
          state.addBudget.status = action.payload?.status ?? "";
          state.addBudget.message = action.payload.data?.message ?? "";
        }
        state.isLoading = false;
      })
      .addCase(addNewbudgetReq.rejected, (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if(state.addBudget)
          {  
            state.addBudget.status = action.payload?.status ?? "";
            state.addBudget.message = action.payload.data?.message ?? "";
            state.isLoading = false;
          }
      });

      // fetch all budget requests
      builder
        .addCase(fetchAllBudgetReqs.pending, (state) => {
          state.status = "loading";
          state.isLoading = true;
        })
        .addCase(fetchAllBudgetReqs.fulfilled, (state, action: PayloadAction<any>) => {
          state.status = "succeeded";
          if(state.budgetRequests)
          {  
            state.budgetRequests.budgetRequests = action.payload.data.data
            state.budgetRequests.status = action.payload?.status ?? "";
            state.budgetRequests.message = action.payload.data?.message ?? "";
            state.budgetRequests.totalPages = action.payload.data.totalPages;
            state.budgetRequests.totalDocuments = action.payload.data.totalDocuments;
          }
          state.isLoading = false;
        })
        .addCase(fetchAllBudgetReqs.rejected, (state, action: PayloadAction<any>) => {
          state.status = "failed";
          if(state.budgetRequests)
            {  
              if(action.payload.status === 404){
                state.budgetRequests.budgetRequests = []
                state.budgetRequests.totalPages = 0;
                state.budgetRequests.totalDocuments = 0;
              }
              state.budgetRequests.status = action.payload?.status ?? "";
              state.budgetRequests.message = action.payload.data?.message ?? "";
            }
            state.isLoading = false;
        });

      // fetch selected budget req

      builder
        .addCase(fetchSelectedBudgetReq.pending, (state) => {
          state.status = "loading";
          state.isLoading=true;
       
        })
        .addCase(fetchSelectedBudgetReq.fulfilled, (state, action: PayloadAction<any>) => {
          state.status = "succeeded";
          state.isLoading=false;
          if(state.selectedBudgetRow)
          {  
            state.budgetRequests?.budgetRequests?.map((budget:any,index:number) => {
              if(budget._id === action.payload.data.data._id)
                if(state?.budgetRequests?.budgetRequests) state.budgetRequests.budgetRequests[index] = action.payload.data.data
            })
            state.selectedBudgetRow.budget = action.payload.data.data;
            state.selectedBudgetRow.status = action.payload?.status ?? "";
            state.selectedBudgetRow.message = action.payload.data?.message ?? "";
          }
        })
        .addCase(fetchSelectedBudgetReq.rejected, (state, action: PayloadAction<any>) => {
          state.status = "failed";
          state.isLoading=false;
          if(state.selectedBudgetRow)
            {  
              state.selectedBudgetRow.status = action.payload?.status ?? "";
              // state.selectedBudgetRow.message = action.payload.data?.message ?? "";
            }
        });

        // FETCH isbns list by pagination
      builder
        .addCase(fetchIsbnList.pending, (state) => {
          state.status = "loading";
          state.isLoading = true;
        })
        .addCase(fetchIsbnList.fulfilled, (state, action: PayloadAction<any>) => {
          state.status = "succeeded";
          if(state.isbns){
            const filteredAccounts = action.payload.data.data.filter((isbn:any) => !state.isbns?.list?.map(stateIsbn => stateIsbn._id).includes(isbn._id))
            action.payload.data?.data?.length && state.isbns.list.push(...filteredAccounts)
            state.isbns.status = action.payload.status ?? 0;
            state.isbns.message = action.payload.data?.message ?? "";
          }
          state.isLoading = false;
        })
        .addCase(fetchIsbnList.rejected, (state, action: PayloadAction<any>) => {
          state.status = "failed";
          if(state.isbns){
            state.isbns.status = action.payload.status;
            state.isbns.message = action.payload.data?.message ?? "";
          }
          state.isLoading = false;
        });
         // FETCH isbns list by comma string
      builder
        .addCase(fetchIsbnByCommaString.pending, (state) => {
          state.status = "loading";
          state.isLoading = true;
        })
        .addCase(fetchIsbnByCommaString.fulfilled, (state, action: PayloadAction<any>) => {
          state.status = "succeeded";
          if(state.isbns){
            
            // update isbn list if match isbns not inside
            const isbnListString = state.isbns.list.map((isbn : any) => isbn.ISBN);
            const matchIsbns = action.payload.data?.matchedBooks.map((isbn : any) => isbn.ISBN);
            
            const filtered = action.payload.data?.matchedBooks.filter((isbn : any) => !isbnListString.includes(isbn.ISBN))

            if(action.payload.data?.matchedBooks.length === filtered.length) {
              state.isbns.list.push(...(action.payload.data?.matchedBooks))
            }
            else if(action.payload.data?.matchedBooks.length != filtered.length){
              state.isbns.list.push(...(filtered))
            }
            else if(!filtered.length){
              const filteredIsbns = state.isbns.list.filter(isbn => !matchIsbns.includes(isbn.ISBN))
              state.isbns.list = filteredIsbns;
            }

            // state.isbns.list = state.isbns.list.filter(isbn => !action.payload.data?.matchedBooks.filter((matchisbn:any) => matchisbn.ISBN === isbn.ISBN))
            state.isbns.unMatchedIsbns = action.payload.data?.unmatchedISBNs ?? [];
            state.isbns.matchedIsbns = action.payload.data?.matchedBooks ?? []
            state.isbns.status = action.payload?.status ?? 0;
            state.isbns.message = action.payload.data?.message ?? "";

            state.isbns.isUploadLoading = false;
            state.isLoading = false;
          }
        })
        .addCase(fetchIsbnByCommaString.rejected, (state, action: PayloadAction<any>) => {
          state.status = "failed";
          if(state.isbns){
            state.isbns.status = action.payload.status;
            state.isbns.message = action.payload.data?.message ?? "";
            state.isLoading = false;
          }
        });

           // FETCH isbns list by comma string
      builder
        .addCase(checkAccountBudget.pending, (state) => {
          state.status = "loading";
        })
        .addCase(checkAccountBudget.fulfilled, (state, action: PayloadAction<any>) => {
          state.status = "succeeded";
          if(state.account_budget){
            state.account_budget.remainingBudget = action.payload.data.remainingBudget['$numberDecimal']
            state.account_budget.status = action.payload.status;
            state.account_budget.message = action.payload.data?.message ?? "";
          }
        })
        .addCase(checkAccountBudget.rejected, (state, action: PayloadAction<any>) => {
          state.status = "failed";
          if(state.account_budget){
            state.account_budget.status = action.payload.status;
            state.account_budget.message = action.payload.data?.message ?? "";
          }
        });
  },
});

export const { emptyAddBudgetStatus, uploadLoading,updateTab, updateUploadLoading, emptyAccountBudget, UpdatedBudgetStatus , emptyUnMatchedIsbns, emptyIsbnsString, setIsbnString, emptyMatchedIsbns, emptyBudgetReq, emptySelectedBudgetReq, selectIsbnDataRow, emptySelectedIsbn } = newBudgetSlice.actions;


export const accountsData = (state: RootState) => state.budgetReducer.accounts;
export const ISBNData = (state: RootState) => state.budgetReducer.isbns
export const allBudgets = (state:RootState) => state.budgetReducer.budgetRequests;
export const selectedBudgetRow = (state:RootState) => state.budgetReducer.selectedBudgetRow;
export const selectedIsbnData = (state: RootState) => state.budgetReducer.selectedIsbnRow;
export const selectedBudgetId=(state:RootState)=>state.budgetReducer.selectedBudgetRow?.budget?._id;
export const status = (state: RootState) => state.budgetReducer.selectedBudgetRow?.budget?.status;
export const accountbudget = (state:RootState) => state.budgetReducer.account_budget;
export const budgetData = (state:RootState) => state.budgetReducer;
export const approvedAndDeclinedComment = (state: RootState) => state.budgetReducer.selectedBudgetRow?.budget?.approved_and_declined_comment;

export default newBudgetSlice.reducer;
