// import React from 'react';

// interface InputFieldProps {
//   id?:string|number;
//   label: string;
//   value?: string | number ;
//   className?: string;
//   inputClassName?:string;
//   onClick?: () => void;
// }

// const InputField: React.FC<InputFieldProps> = ({ label, value, className = '' ,inputClassName, onClick }) => {
//   return (
//     <div className={`flex text-[14px] flex-col flex-1 shrink ${className}`} onClick={() => {
//       if (onClick) onClick(); 
//     }}>
//       <label className=" font-semibold leading-none text-neutral-800">
//         {label}
//       </label>
//       <div className={`flex items-center mt-2   text-neutral-500 ${inputClassName}  rounded-md border border-solid bg-zinc-100 border-neutral-200 
//      `}>
//         <div className=" mx-2 my-4  text-[14px]">
//           {value}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default InputField;
import React from 'react';

interface InputFieldProps {
  id?: string | number;
  label: string;
  value?: string | number;
  className?: string;
  inputClassName?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void; 
  disabled?:boolean
  required?:boolean
  name?:string
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  value,
  className = '',
  inputClassName,
  onChange,
  disabled=false,
  required=false,
  name
}) => {
  return (
    <div className={`flex text-[14px] flex-col flex-1 shrink ${className}`}>
      <label className="font-semibold leading-none text-neutral-800">
        {label}
        {required && <span className="text-red-600 mx-1">*</span>}
      </label>
      <input
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled} // Capture input changes
        className={`flex items-center text-[14px] mt-2 text-neutral-500 ${inputClassName} rounded-md border border-solid  border-neutral-300 p-4`}
      />
    </div>
  );
};

export default InputField;
