import React, { useEffect, useRef, useState } from 'react'
import { CommonDataTable } from '../../common-components/common-material-react-table/CommonMaterialTable'
import { invoicedata, InvoicesHeaders } from '../../constants/tableConstants/tableHeaders'
import NewReq from '../../common-components/New request/newReq'
import { FaCross, FaDownload, FaEye, FaFileUpload, FaRegEye, FaRegFilePdf, FaUpload } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { userProfile } from '../../redux/reducers/profileReducer'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TableCell,
} from "@mui/material";
import { fetchUsers, user,Users } from '../../redux/reducers/UserReducer'
import { useNavigate, useParams } from 'react-router-dom'
import { AppDispatch, RootState } from '../../redux/store'
import { emptyApproverInvoice, emptyMatch, emptySelectedInvoice, emptyStatusMessage, fetchInvoices, PostmatchInvoices, postSearchInvoice, postUploadInvoices, startSelectedLoading, updateTotalDocuments, updateUploadLoading } from '../../redux/reducers/InvoiceReducer'
import { InvoiceData } from '../../redux/reducers/InvoiceReducer'
import moment from 'moment'
import { toast } from 'react-toastify'
import { MdCancel, MdVerified } from 'react-icons/md'
import axios from 'axios'
import { Controller, useForm } from 'react-hook-form'
import Select, {components, SelectInstance} from 'react-select'
import AmountField from './AmountField'
import InputField from './InputField'
import DatePickerComponent from '../newbudetrequest/DatePicker'
import { formatCurrency } from '../../common-components/commonFunctions'

interface optionObj {
  value: string;
  label: string;
}

interface invoiceFormObj {
  file: File | null,
  approvers: optionObj[];
  required_approval:number;
  InvoiceId:string;
  Invoice_number: number | string;
  Invoice_Date: Date;
  Invoice_amount: number | string;
}

const BudgetRequest = () => {
  const {handleSubmit, setError, register, formState:{errors}, reset, watch, setValue, control} = useForm<invoiceFormObj>()
  const invoices = useSelector((state: RootState) => state.invoiceReducer.data);
  const userData = useSelector(user)
  const [open, setOpen] = useState(false);
  const statusCode=useSelector((state:RootState)=>state.invoiceReducer.statusCode)
  const message=useSelector((state:RootState)=>state.invoiceReducer.message)
  const isUploading=useSelector((state:RootState)=>state.invoiceReducer.isUploadLoading)
  const navigate=useNavigate()
  const dispatch: AppDispatch = useDispatch();
  const fileInputRef=useRef<HTMLInputElement>(null)
  const totalDocuments=useSelector((state:RootState)=>state.invoiceReducer.totalDocuments)
  const totalPages=useSelector((state:RootState)=>state.invoiceReducer.totalPages)
  const matchStatus = useSelector((state:RootState) => state.invoiceReducer.matchFile.status)
  const matchMessage = useSelector((state:RootState) => state.invoiceReducer.matchFile.message)
  const users = useSelector(Users)    // account user
  const [searchTerm, setSearchTerm] = useState("");
  const approverInvoice = useSelector((state:RootState) => state.invoiceReducer.approverInvoice)
  const uploadLoading = useSelector((state:RootState) => state.invoiceReducer.isLoading)
  const[amountInputShow,setAmountInputShow]=useState<boolean>(false)
  const selectedLoading = useSelector((state:RootState) => state.invoiceReducer.selectedLoading);


  const profile = useSelector((state:RootState) => state.UserReducer.userData)

  const userApproverOptions = users?.map(user => ({
    value: user._id ?? '',
    label: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
  }));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset()
    dispatch(emptyApproverInvoice())
  };

  useEffect(() => {
    if(!searchTerm?.length)
    dispatch(fetchInvoices({page:1,limit:10} ));
  },[searchTerm])

  useEffect(() => {
    if(open){
      dispatch(fetchUsers({role:['approver']}))
      // if(userData?.role?.includes("approver"))  
      //   setValue('approvers',[{value:userData._id,label:`${userData.first_name} ${userData.last_name}`}] as optionObj[])
    }
  }, [open]);

  useEffect(()=>{
    if(statusCode===201){
      dispatch(updateTotalDocuments())
      toast.success(message)
      if(approverInvoice.canApprove)
        reset(approverInvoice)
      else handleClose()

    }else if(statusCode===404 || statusCode===400){
      toast.error(message)
    }
    dispatch(emptyStatusMessage())
  },[statusCode,message])

  const handleSVGClick = () => {
    if(!isUploading)
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
        setValue("file",file)
        setError("file",{message:""})
      if (fileInputRef.current) fileInputRef.current.value = '';
    }
  };

  const handleRemoveFile = () => {
    setValue("file",null)
    if (fileInputRef.current) fileInputRef.current.value = '';
  };

  const handleVerify = (data: any) => {
    if (data && data._id) {
      navigate(`/VerifyInvoices/${data._id}`);
    } else {
      console.error("Invoice data is invalid");
    }
  };

  const handleUpload = (data : invoiceFormObj) => {
    // data["required_approval"] = data["required_approval"] ?? false;
    if(data.approvers?.length) 
      data['approvers'] = data.approvers.map((approver:any) => approver.value)
    // else if(!data.approvers?.length) toast.error("Something Went Wrong")
      if (!watch("file")) {
        toast.error(message);
        return;
      }
    // if(file.type !=="pdf"){
    //   toast.error("Please upload a PDF file")
    // }
    dispatch(postUploadInvoices(data)) 

  };

  const handleNextCallback=( page:number )=>{
    dispatch(fetchInvoices({page:page,limit:10}))
  };

  const updateInvoice = (data : any) => {
      data['match'] = true
      dispatch(PostmatchInvoices(data))
  };

  const handleDownload=(Invoice:any)=>{
    window.open(Invoice.Invoice_URL)
    // const link=document.createElement('a');
    // link.href=Invoice_URL;
    // link.download = Invoice_URL.split('/').pop() || 'invoice.pdf';
    // document.body.appendChild(link);
    // link.click();
  }

  // const downloadPDF = async (Invoice:any) => {
  //   try {
  //     const response = await axios.get(Invoice.Invoice_URL, {
  //       responseType: "blob", // This tells Axios to handle the response as binary data
  //     });

  //     // Create a URL for the Blob and trigger a download
  //     const blobUrl = URL.createObjectURL(response.data);
  //     const link = document.createElement("a");
  //     link.href = blobUrl;
  //     link.download = `${Invoice.InvoiceId}.pdf`; // Set the desired file name with fallback

  //     document.body.appendChild(link); // Append link to body
  //     link.click(); // Trigger the download
  //     link.remove(); // Remove the link after downloading

  //     // Revoke the object URL to free up memory
  //     URL.revokeObjectURL(blobUrl);
  //   } catch (error) {
  //     console.error(error, "Error while getting PDF");
  //   }
  // };


  // const handleDownload=async (Invoice_URL:string)=>{
  //   try {
  //     const response = await fetch(Invoice_URL, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/pdf',
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error('Network response was not ok.');
  //     }

  //     const blob = await response.blob();
  //     const link = document.createElement('a');
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = fileName;
  //     link.click();

  //     // Clean up
  //     window.URL.revokeObjectURL(link.href);
  //   } catch (error) {
  //     console.error('Error downloading the file:', error);
  //   }


  // }

  useEffect(() => {
    if(matchStatus === 200){
      toast.success(matchMessage);
      handleClose();
    }else if(matchStatus===404){
      toast.error(matchMessage)
    }
    dispatch(emptyMatch())
    dispatch(emptySelectedInvoice())
  },[matchStatus,matchMessage])

  // const handleSearch=()=>{
  //   const trimmedSearchTerm = searchTerm.trim();
  //   setSearchTerm(trimmedSearchTerm)
  //   dispatch(postSearchInvoice({search:"6758",searchTerm:searchTerm.trim()}))
  // }
  const handleSearch = () => {
    const trimmedSearchTerm = searchTerm.trim();
    if (trimmedSearchTerm) {
      dispatch(postSearchInvoice({ search: trimmedSearchTerm}));
    }
    
  
  };


  const noResultsFound = invoices.length === 0 && searchTerm.trim() !== "";

  const handlematch=(data:any)=>{
    dispatch(PostmatchInvoices(data))
  }

  return (
    <div className='font-poppins mx-9'>
      <section>
        <NewReq title={`${userData?.role?.includes('requestor') ? 'Upload' : 'Verify'} Invoices`}
         element={
          <div>
            {
              userData?.role?.includes('requestor') ? 
              <button  onClick={handleClickOpen}
                className="flex font-poppins p-2  bg-red-600 rounded-lg text-[13px] font-semibold text-white cursor-pointer"
              >
             +   New Invoice
              </button>
          : <></>
            }
          </div>
            } />
        <div className="">
          <div className="">
            <CommonDataTable
                enableBottomToolbar={true}
                NodataFound={searchTerm?.length ? `There are no Invoices with Invoice number ${searchTerm}.` :(userData?.role?.includes("admin") || (userData?.role?.includes("approver") && !userData?.role?.includes("requestor"))) ? "There are no invoices yet." : "There are no invoices yet, click on New Invoice to add some"}
                changeSelectedColumnDataDesign={["Status", "updatedAt","Invoice_Date"]}
                changedDataCellColumn={(header, accessor) => {
                  if (accessor === "Status") {
                    return {
                      accessorKey: accessor,
                      header: header,
                      Cell: ({ row }: { row: any }) => (
                        <div>
                              {  
                        row.original.Invoice_status===0 
                        ?
                        <div className='text-yellow-500 font-poppins ' >Pending</div>
                      :
                      row.original.Invoice_status===1 
                      ? 
                        <span 
                        className='text-green-500 text-[14px] font-poppins' > Verified</span> 
                      : row.original.Invoice_status===2 &&
                        <div className='text-red-500 text-[14px]  font-poppins '>Declined</div> }
                        </div>
                      ),
                    };
                  } else if (accessor === "updatedAt") {
                    return {
                      accessorKey: accessor,
                      header: header,
                      Cell: ({ row }: { row: any }) => (
                        <div>
                          {moment(row.original.updatedAt).format("l")}
                        </div>
                      ),
                    };
                  }
                  else if (accessor === "Invoice_Date") {
                    return {
                      accessorKey: accessor,
                      header: header,
                      Cell: ({ row }: { row: any }) => (
                        <div>
                          {/* {moment(row.original.Invoice_Date).format("MM-DD-Y")} */}
                          {row.original.Invoice_Date? moment(row.original.Invoice_Date).format("l"):"-"}
                        </div>
                      ),
                    };
                  }
                  
                  
                  return {
                    accessorKey: accessor,
                    header: header,
                  };
                }}

              tableHeaders={InvoicesHeaders}
              data={invoices}
              showRowNumbers
              pageSize={10}
              callback={() => {}}
              colSizes={[]} 
              nextCallback={handleNextCallback}
              enableGlobalFilter={false}
              actionButtons
              totalDocuments={totalDocuments}
              totalPages={totalPages}
              enableTopToolbar={true}
              topToolbar={({ }) => (
                <div className="flex text-sm font-bold py-3 px-6 justify-start  sm:justify-start md:justify-end  ">
                <div className="w-full md:w-[50%] sm:w-[50%] xs:w-[50%] flex gap-8">
                  <div className="relative w-full">
                    <input
                      type="text"
                      onKeyDown={(event) => {
                        if(event.key === "Enter")
                          handleSearch(); 
                      }}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Search by Invoice Id"
                      className="border border-solid border-neutral-200 text-[14px] font-poppins rounded-lg w-full  focus:border-blue-500 focus:outline-none p-2  "
                    />
                  {searchTerm?.length ?<div title="Clear All" onClick={() =>{
                    if(searchTerm){
                      setSearchTerm("")
                      dispatch(fetchInvoices({page:1,limit:10} ));

                    }
                    // else if(searchTerm!==trim)
                        // dispatch(postSearchInvoice({ }));
                        // handleSearch()
                      }} 
                      className="flex absolute items-center right-[-10px] top-2.5 ">
                      <MdCancel className="text-red-600 w-5 h-5 cursor-pointer"/>
                    </div>:""}
                  </div>
                  <div className="flex">
                    <button
                      className="bg-red-600 p-2.5 text-white font-semibold text-[14px] hover:bg-red-700  rounded-md cursor-pointer "
                      onClick={handleSearch}  
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
              )}
           
              customActionButton={(row : any) => (
                  <div className="flex justify-center items-center "> 
                  <div
                  className=" ">
                    <span  className="cursor-pointer m-2"
                  onClick={() => handleVerify(row.original)} >
                    <FaRegEye className=' border border-none bg-transparent  w-5 h-4 ' title='View Invoice'/></span>
                  <span
                 
                   className='cursor-pointer'
                    title="Download Invoice"
                    > 
                    {/* <a href= {row.original.Invoice_URL} 
                     download={row.original.Invoice_URL}
                     title="Download Invoice"> */}
                      {/* <FaDownload className=' w-5 h-4'   onClick={() =>handleDownload(row.original)}  /> */}
                      {/* </a>   */}
                      </span>
                  </div>
                  {/* {  
                  row.original.Invoice_status===0 
                  ?
                  <></>
                :
                 row.original.Invoice_status===1 
                 ? 
                  <span onClick={() => handleVerify(row.original)} 
                  className='text-green-500 text-[14px] font-semibold cursor-pointer' > Verified</span> 
                 : 
                  <div onClick={() => handleVerify(row.original)} className='text-red-500 text-[14px] font-semibold cursor-pointer'>Declined</div> } */}
                  </div>  
              )}
            />
          </div>
        </div>
      </section>
    <div>
      
    </div>
    {
      open &&  (
        <div   className="fixed inset-0 bg-black bg-opacity-50 flex justify-end items-center z-20 ">
          <div className="bg-rose-100">
            <div className="  bg-white rounded-lg w-[300px] md:w-[700px] sm:w-[500px] h-screen  ">
              <div className="flex justify-center bg-rose-100 mb-10">
                <h2 className="text-[25px] ml-5  bg-rose-100 w-full rounded-md">
                  Upload Invoice
                </h2>
                <button
                onClick={handleClose}
                 
                  className="flex gap-1.5 items-center self-stretch px-1 py-0.5 my-auto text-base font-black text-center whitespace-nowrap rounded-md border border-solid
         border-slate-500 border-opacity-20 text-slate-500 mr-10 cursor-pointer  "
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 6L6 18M6 6l12 12"
                      stroke="#808080"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <form className="m-6"  onSubmit={handleSubmit(approverInvoice.canApprove ? updateInvoice : handleUpload)}>
                {!approverInvoice.canApprove ?
                <div>
                  {watch("file") && (
                          <div className="flex justify-center text-center m-0 p-0 mb-5">
                            <div className="relative flex flex-col border-[1px] rounded-[15px] p-[10px] border-dashed items-center">
                            <div>
                              <FaRegFilePdf className='w-[40px] h-[40px]' />
                            </div>
                            <div>
                              <p className="text-gray-700 font-semibold">File Name: {watch("file")?.name || ""}</p>
                            </div>
                            {!isUploading &&  <div className="z-30 absolute top-0 right-[-10px] font-semibold  text-red-700 cursor-pointer h-1 flex items-center justify-center text-sm rounded-lgbg-black " onClick={handleRemoveFile} > 
                              <MdCancel className="  text-red-600 w-7 h-7 cursor-pointer" title='Remove File' />
                              </div>}
                            </div>
                          </div>
                        )}

                <input
                  type="file"
                  {...register("file",{required:"Invoice file is required"})}
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  accept=".pdf" 
                  disabled={isUploading}
                />
              
                  <div  onClick={handleSVGClick} className="w-full border-2 h-[90px] border-gray-300 border-dashed rounded-md font-poppins custom-placeholder flex justify-center items-center cursor-pointer  ">
                    <div  className='mb-8'>
                    <FaUpload />
                    </div>
                      <div className=" absolute text-neutral-500 text-center ">Upload Here</div>
                    
                      {errors.file && (
                            <div className=" mb-4 absolute text-red-500  text-center mt-16">
                              {errors.file.message}
                            </div>
                          )}
                  </div>
                    <div className="flex items-center mt-5 font-poppins">
                      <Controller
                          name="required_approval"
                          control={control}
                          rules={{
                            // required:!userData?.role?.includes("approver") && "Please select an approver",
                            onChange:() => {
                              if(!watch("required_approval"))
                                setValue('approvers',[] as optionObj[])
                            }
                          }}
                          render={({ field }) => (
                            <div className="relative">
                              <input
                                type="checkbox"
                                className="mr-2 "
                                {...field}
                              />
                              {errors?.required_approval && <span className="text-red-500 flex whitespace-nowrap absolute bottom-[-25px]">{errors?.required_approval.message}</span>}
                            </div>
                          )}
                      />
                      <label className="font-bold">Required Approval</label>
                    </div>
                  {watch("required_approval") && 
                                  <Controller
                                      name="approvers"
                                      control={control}
                                      rules={{
                                        required:
                                        watch("required_approval") ?
                                         "Approver is required"
                                         :false
                                      }}
                                      render={({ field: { onChange, onBlur, value, name, ref} }) => (
                                        <div className="flex relative flex-col bg-white w-full mb-5 mt-2">
                                          <label className="text-sm leading-none  text-neutral-800 py-2  font-bold">Select Approver 
                                          {/* <span className="text-red-500">*</span> */}
                                          </label>
                                          <Select 
                                              isDisabled={isUploading}
                                              placeholder={"Select Approver"}
                                              ref={ref}
                                              name={name} 
                                              value={value}
                                              onBlur={onBlur}
                                              noOptionsMessage={() => 'Approver not found'}
                                            //   defaultValue={
                                            //     userApproverOptions.filter((user:any) => {
                                            //     if(profile?.role?.includes("approver")){
                                            //       if(user.value === profile._id)
                                            //       {  
                                            //         setValue("approver",[{value: user.value ,label: user.label}])
                                            //         return user;
                                            //       }
                                            //     }
                                            //   })
                                            // }
                                            styles={{
                                              container:(provided) => ({
                                                // backgroundColor:"red",
                                                // width:"30vw",
                                                // maxWidth:"37vw",
                                                width:"100%",
                                                display:"flex"
                                              }),
                                              multiValue: (provided) => ({
                                                ...provided,
                                                height:"30px",
                                                display:"flex",
                                                alignItems:"center",
                                                borderRadius:"6px",
                                                minWidth:"none",
                                              }),
                                              valueContainer:(provided) =>({
                                                position:"relative",
                                                maxWidth:"28vw",
                                                width:"100%",
                                                paddingLeft:"8px",
                                                display:"flex",
                                                alignItems:"center",
                                                height:"40px",
                                                overflowX:"auto",
                                              }),
                                              control:(provided) => ({
                                                display:"flex",
                                                // maxWidth:"37vw",
                                                // width:"510px",
                                                height:"40px",
                                                alignItems:"center",
                                                justifyContent:"space-between",
                                                width:"100%"
                                              }),
                                              input: (provided) => ({
                                                ...provided,
                                                position:`${watch('approvers')?.length ? 'relative': 'absolute'}`,
                                                left:6,
                                              //   display:"flex",
                                              //   alignItems:"center",
                                              //   textAlign:"center",
                                              //   justifyContent:"center",
                                                marginRight:"60px",
                                              //   fontSize: '16px',
                                              })
                                            }}
                                              onChange={(selectedOptions) => onChange(selectedOptions)}
                                              isMulti
                                              // closeMenuOnSelect={true}
                                              hideSelectedOptions={true}                    
                                              options={userApproverOptions} 
                                              className="border border-solid border-neutral-200 font-poppins rounded-lg w-full text-[14px]"
                                            /> 
                                          
                                            {errors.approvers && <span className="text-red-500 absolute bottom-[-25px]">{errors.approvers.message}</span>}
                                        </div>
                                      )}
                                      />
                    } 
                                    <button disabled={isUploading} type="submit" className="  flex justify-center gap-3 items-center mt-8 bg-red-600 font-bold cursor-pointer text-[15px] font-poppins  text-white w-full h-12 rounded-md">
                  <span
                    className=""
                  >
                    Submit
                  </span>
                  {isUploading ? 
                                        (
                                          <div role="status">
                                              <svg aria-hidden="true" className="w-6 h-6 text-gray-200 animate-spin px-1 dark:text-gray-600 fill-black flex items-center justify-center " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                              </svg>
                                          </div>
                                        ):""}
                </button>
                </div>
                :
                <div className="flex flex-col ">
                <div className="flex gap-6 items-start w-full text-[14px] leading-none whitespace-nowrap  mt-6">
                <Controller
                                  control={control}
                                  name="InvoiceId"
                                  render={({ field }) => (
                                    <InputField
                                      onChange={field.onChange}
                                      name={field.name}
                                      label={"Invoice Id"}
                                      disabled
                                      value={field.value}
                                      inputClassName='overflow-y-auto'
                                      className='overflow-hidden'
                                    />
 
                                  )} />
                </div>
              {( (approverInvoice.Invoice_status === 0 ? (profile?.role?.includes("approver") && approverInvoice.canApprove) : true) ) &&
                  <div className='w-full'>
                    <div className="flex gap-6 relative items-start w-full text-[14px] leading-none whitespace-nowrap  mt-6">
                    <Controller
                                      control={control}
                                      name="Invoice_number"
                                      rules={{
                                        // required:"Invoice Number is required",
                                        // onChange:(e) => setValue("Invoice_number",e.target.value)
                                      }}
                                      render={({ field }) => (
                                        <InputField
                                          // required
                                          onChange={field.onChange}
                                          name={field.name}
                                          label={"Invoice Number"}
                                          value={field.value}
                                          inputClassName='overflow-y-auto'
                                          className='overflow-hidden'
                                          // disabled = { approverInvoice.Invoice_status !==0 }
                                          />
                                        )} />
                                        {errors.Invoice_number && (
                                                <span className="text-red-500 absolute bottom-[-20px]">
                                                  {errors.Invoice_number.message}
                                                </span>
                                              )}
                    </div>
                    <div className="flex gap-6 items-start w-full text-base leading-none whitespace-nowrap max-md:max-w-full mt-6">
                      <div className={`flex text-[14px] flex-col flex-1 shrink `}>
         
                                        <div className="relative flex w-full flex-col ">
 
                                    <Controller
                                      control={control}
                                      name="Invoice_Date"
                                      rules={{
                                        // required:"Invoice Date is required",
                                      }}
                                      render={({ field }) => (
                                        <div className="relative flex w-full flex-col ">
                                          <DatePickerComponent
                                          invoiceStatus={approverInvoice.Invoice_status}
                                            // required
                                            labelClassname='font-semibold text-neutral-800'
                                            inputClassname="p-4  "
                                            label="Invoice Date"
                                            placeholder="Select Date"
                                            iconClassname="mt-4 "
                                            // disabled={ approverInvoice.Invoice_status !==0 }
                                            // min={new Date()}
                                            value={field.value}
                                            onChange={(date) => field.onChange(date)}
                                          />
                                            {errors?.Invoice_Date?.message && (
                                              <span className="text-red-500 absolute bottom-[-20px]">
                                                {typeof errors.Invoice_Date.message === 'string' ? errors.Invoice_Date.message : "Invalid error message"}
                                              </span>
                                            )}
                                        </div>
                                      )}
                                    />
                                         
                                        </div>
                                 
                      </div>
                      </div>
                      <div className="flex relative gap-6 items-start text-[14px]  text-black mt-6 mb-6 w-full whitespace-nowrap max-md:max-w-full">
                                      <Controller
                                        control={control}
                                        name="Invoice_amount"
                                        rules={{
                                          // required:"Invoice Amount is required",
                                        }}
                                        render={({ field }) => (
                                          <div className='w-full'>
                                                  { amountInputShow ?
                                                  <AmountField
                                                  amountInputShow={amountInputShow}
                                                    onChange={field.onChange}
                                                    onKeyDown={(event:any) => {
                                                      if(event.key === "Enter" || event.key ==="Tab"){
                                                        if(watch("Invoice_amount")){
                                                          if(isNaN(Number(watch("Invoice_amount")))){
                                                            setValue("Invoice_amount","")
                                                          }
                                          
                                                          if(Number(watch("Invoice_amount")) < 0){
                                                            setValue("Invoice_amount","")
                                                          }
                                                          setAmountInputShow(!amountInputShow)
                                          
                                                        }
                                                      }
                                                    }}
  
                                                    onBlur={() => {
                                                      if(watch("Invoice_amount")){
                                                            if(isNaN(Number(watch("Invoice_amount")))){
                                                                setValue("Invoice_amount","")
                                                              }
                                                              if(Number(watch("Invoice_amount")) < 0){
                                                                      setValue("Invoice_amount","")
                                                                    }
                                                                    setAmountInputShow(!amountInputShow)
                                                      }
                                                    }}
  
                                                    name={field.name}
                                                    // required
                                                    amount= {field.value}
                                                    // disabled = { approverInvoice.Invoice_status !==0 }
                                                    className='w-full'
                                                  />
  
                                            :
                                            ( <div className={`flex flex-col`} onClick={() => {
                                              setAmountInputShow(!amountInputShow)
                                            }}>
                                              <label htmlFor="amount" className="self-start font-semibold leading-none text-neutral-800">
                                                    Amount
                                                    {/* <span className="text-red-600 mx-1">*</span> */}
                                                  </label>
                                            
                                              <div className={`flex text-[14px] bg-white rounded-md border border-solid border-neutral-300 mt-2 text-gray-500 leading-none ${watch("Invoice_amount")? "p-5":"p-6" }`}>
                                                  {
                                                    watch("Invoice_amount")
                                                      ?
                                                    `${formatCurrency(Number(watch("Invoice_amount")), JSON.stringify(watch("Invoice_amount")).split(".")[1]?.length ? JSON.stringify(watch("Invoice_amount")).split(".")[1]?.length - 1 : 2)}`
                                                      :
                                                    field.value
                                                  }
                                              </div>
                                            </div>)
                                            }
                                          </div>
                                              )} />
                                              {errors?.Invoice_amount?.message && (
                                                <span className="text-red-500 absolute bottom-[-20px]">
                                                  {typeof errors.Invoice_amount.message === 'string' ? errors.Invoice_amount.message : "Invalid error message"}
                                                </span>
                                              )}
                      </div>
                    </div>
              }
              <div className="flex  items-center max-md:max-w-full gap-3 ">
                <button disabled={uploadLoading} type='button' className="flex items-center justify-center cursor-pointer flex-1 shrink self-stretch font-medium whitespace-nowrap basis-0 py-3 px-5 font-poppins rounded-md bg-transparent text-black border border-solid border-gray-400 "
                onClick={()=>{
                  dispatch(startSelectedLoading("decline"))
                  handlematch({ id:approverInvoice.id,match:false,Invoice_Date:approverInvoice.Invoice_Date ,Invoice_amount:approverInvoice.Invoice_amount ,Invoice_number:approverInvoice.Invoice_number })
                }}
                >
                  Decline
                  {(selectedLoading === "decline" && uploadLoading) ? 
                                            (
                                              <div role="status">
                                                  <svg aria-hidden="true" className="w-6 h-6 text-gray-200 animate-spin px-1 dark:text-gray-600 fill-black flex items-center justify-center " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                  </svg>
                                              </div>
                                            ):""}
                </button>
                <button disabled={uploadLoading} onClick={() => {
                  dispatch(startSelectedLoading("verify"))
                }} type='submit' className="flex-1 flex items-center justify-center  cursor-pointer shrink self-stretch font-medium whitespace-nowrap basis-0 py-3 px-5 font-poppins rounded-md bg-green-600 text-white" 
                  >Mark as Verified
                    {(selectedLoading === "verify" && uploadLoading) ? 
                                            (
                                              <div role="status">
                                                  <svg aria-hidden="true" className="w-6 h-6 text-gray-200 animate-spin px-1 dark:text-gray-600 fill-black flex items-center justify-center " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                  </svg>
                                              </div>
                                            ):""}
                </button>
              </div>
              </div>
                }
              </form>
            </div>
          </div>
        </div>
      )
    }
    </div> 
  )
}

export default BudgetRequest
