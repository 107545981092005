import React, { useEffect, useRef, useState } from 'react';
import InputField from './InputField';
import AmountField from './AmountField';
import InvoicePreview from './InvoicePreview';
import InvoiceReducer, {  getSelectedInvoice, InvoiceData, emptyMatch, emptySelectedInvoice, updateUploadInvoice, PostmatchInvoices } from '../../redux/reducers/InvoiceReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DatePickerComponent from '../newbudetrequest/DatePicker';
import { Controller, useForm } from 'react-hook-form';
import { formatCurrency } from '../../common-components/commonFunctions';
 
interface FormValues {
  InvoiceId:string;
  Invoice_number: number | string;
  Invoice_Date: Date;
  Invoice_amount: number | string;
}
 
const InvoiceForm: React.FC = () => {
  const {handleSubmit,watch,formState:{errors},control, reset, setValue} = useForm<FormValues>()
  // const invoices = useSelector((state: RootState) => state.invoiceReducer.data);
  const dispatch = useDispatch<AppDispatch>();
  const params= useParams();
  const invoices = useSelector((state:RootState)=>state.invoiceReducer.uploadedFile);
 const navigate=useNavigate()
 const matchfileStatus=useSelector((state:RootState)=>state.invoiceReducer.matchFile.status)
  const profile = useSelector((state:RootState) => state.UserReducer.userData)
 
 const[amountInputShow,setAmountInputShow]=useState<boolean>(false)
 
  useEffect(() => {
    if(params.id){
      dispatch(getSelectedInvoice({ _id: params.id }));
    }
    return (()=>{
    dispatch(emptySelectedInvoice())
    })
  }, [params.id]);
 
  useEffect(() => {
    reset(invoices as any)
  },[invoices])
 
  useEffect(()=>{
    if(matchfileStatus!==0)
      navigate("/invoices")
  },[matchfileStatus])
 
 
  const onSubmit = (data:any) => {
    data['id'] = data._id;
    data['match'] = true
    dispatch(PostmatchInvoices(data))
  }

  return (
    < div className="overflow-hidden" >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col rounded-none p-6 ">
        <section className="flex px-4 bg-white rounded-2xl max-md:px-5 w-full gap-8">
          <div className="flex z-10 flex-col mb-0 max-md:mb-2.5 w-[60%]">
            <p className='font-bold'>Verify Invoices</p>
            <div className="flex flex-col   ">
              <div className="flex flex-col ">
                <div className="flex gap-6 items-start w-full text-[14px] leading-none whitespace-nowrap  mt-6">
                <Controller
                                  control={control}
                                  name="InvoiceId"
                                  render={({ field }) => (
                                    <InputField
                                    onChange={field.onChange}
                                      name={field.name}
                                      label={"Invoice Id"}
                                      disabled
                                      value={field.value}
                                      inputClassName='overflow-y-auto'
                                      className='overflow-hidden'
                                    />
 
                                  )} />
                </div>
              {( (invoices.Invoice_status === 0 ? (profile?.role?.includes("approver") && invoices.canApprove) : true) ) &&
                  <div className='w-full'>
                    <div className="flex gap-6 relative items-start w-full text-[14px] leading-none whitespace-nowrap  mt-6">
                    <Controller
                                      control={control}
                                      name="Invoice_number"
                                      rules={{
                                        // required:"Invoice Number is required",
                                        // onChange:(e) => setValue("Invoice_number",e.target.value)
                                      }}
                                      render={({ field }) => (
                                        <InputField
                                          // required
                                          onChange={field.onChange}
                                          name={field.name}
                                          label={"Invoice Number"}
                                          value={invoices.Invoice_status === 0 ? field.value : field.value ? field.value : "N/A"}
                                          inputClassName='overflow-y-auto'
                                          className='overflow-hidden'
                                          disabled = { invoices.Invoice_status !==0 }
                                          />
                                        )} />
                                        {errors.Invoice_number && (
                                                <span className="text-red-500 absolute bottom-[-20px]">
                                                  {errors.Invoice_number.message}
                                                </span>
                                              )}
                    </div>
                    <div className="flex gap-6 items-start w-full text-base leading-none whitespace-nowrap max-md:max-w-full mt-6">
                      {/* <InputField
                        label={"Invoice Date"}
                        value={moment(invoices.Invoice_Date).format("MM DD YYYY")}
                        inputClassName='overflow-y-auto'
                        className='overflow-hidden'
                      /> */}
                      <div className={`flex text-[14px] flex-col flex-1 shrink `}>
         
          {/* <input
            type="text"
            placeholder='Date'
            value={invoices.Invoice_Date && moment(invoices.Invoice_Date).format("YYYY-MM-DD")}
            // value={invoices.Invoice_Date}
            onChange={(e) => handleChange( 'Invoice_Date',e)}
            disabled = { invoices.Invoice_status !==0 }
            className={`flex items-center mt-2 text-neutral-500  rounded-md border border-solid bg-white border-neutral-300 p-4`}
          /> */}
         
                                        <div className="relative flex w-full flex-col ">
 
                                    <Controller
                                      control={control}
                                      name="Invoice_Date"
                                      rules={{
                                        // required:"Invoice Date is required",
                                      }}
                                      render={({ field }) => (
                                        <div className="relative flex w-full flex-col ">
                                          <DatePickerComponent
                                          invoiceStatus={invoices.Invoice_status}
                                            // required
                                            labelClassname='font-semibold text-neutral-800'
                                            inputClassname="p-4  "
                                            label="Invoice Date"
                                            placeholder="Select Date"
                                            iconClassname="mt-4 "
                                            disabled={ invoices.Invoice_status !==0 }
                                            // min={new Date()}
                                            value={field.value}
                                            onChange={(date) => field.onChange(date)}
                                          />
                                            {errors?.Invoice_Date?.message && (
                                              <span className="text-red-500 absolute bottom-[-20px]">
                                                {typeof errors.Invoice_Date.message === 'string' ? errors.Invoice_Date.message : "Invalid error message"}
                                              </span>
                                            )}
                                        </div>
                                      )}
                                    />
                                         
                                        </div>
                                 
        </div>
                    </div>
                    <div className="flex relative gap-6 items-start text-[14px]  text-black mt-6 mb-6 w-full whitespace-nowrap max-md:max-w-full">
                    <Controller
                                      control={control}
                                      name="Invoice_amount"
                                      rules={{
                                        // required:"Invoice Amount is required",
                                      }}
                                      render={({ field }) => (
                                        <div className='w-full'>
                                                { amountInputShow ?
                                                <AmountField
                                                amountInputShow={amountInputShow}
                                                  onChange={field.onChange}
                                                  onKeyDown={(event:any) => {
                                                    if(event.key === "Enter" || event.key ==="Tab"){
                                                      if(watch("Invoice_amount")){
                                                        if(isNaN(Number(watch("Invoice_amount")))){
                                                          setValue("Invoice_amount","")
                                                        }
                                         
                                                        if(Number(watch("Invoice_amount")) < 0){
                                                          setValue("Invoice_amount","")
                                                        }
                                                        setAmountInputShow(!amountInputShow)
                                         
                                                      }
                                                    }
                                                  }}
 
                                                  onBlur={() => {
                                                    if(watch("Invoice_amount")){
                                                          if(isNaN(Number(watch("Invoice_amount")))){
                                                              setValue("Invoice_amount","")
                                                            }
                                                            if(Number(watch("Invoice_amount")) < 0){
                                                                    setValue("Invoice_amount","")
                                                                  }
                                                                  setAmountInputShow(!amountInputShow)
                                                    }
                                                  }}
 
                                                  name={field.name}
                                                  // required
                                                  amount= {invoices.Invoice_status === 0 ? field.value : field.value ? field.value : "N/A"}
                                                  disabled = { invoices.Invoice_status !==0 }
                                                  className='w-full'
                                                />
 
    :
    ( <div className={`flex flex-col`} onClick={() => {
      invoices.Invoice_status === 0 && setAmountInputShow(!amountInputShow)
    }}>
      <label htmlFor="amount" className="self-start font-semibold leading-none text-neutral-800">
            Amount
            {/* <span className="text-red-600 mx-1">*</span> */}
          </label>
     
      <div className={`flex text-[14px] ${invoices.Invoice_status === 0 ? "bg-white" : "bg-gray-50"}  rounded-md border border-solid border-neutral-300 mt-2 text-gray-500 leading-none ${watch("Invoice_amount")? "p-5":"p-6" }`}>
          {
            watch("Invoice_amount")
              ?
            `${formatCurrency(Number(watch("Invoice_amount")))}`
              :
            invoices.Invoice_status === 0 ? field.value : "N/A"
          }
      </div>
    </div>)
    }
                                        </div>
                      )} />
                      {errors?.Invoice_amount?.message && (
                                              <span className="text-red-500 absolute bottom-[-20px]">
                                                {typeof errors.Invoice_amount.message === 'string' ? errors.Invoice_amount.message : "Invalid error message"}
                                              </span>
                                            )}
                    </div>
                  </div>
              }
              </div>
            </div>
          </div>
        <InvoicePreview />
        </section>
      </form>
      {/* <div className='py-6'>
      </div> */}
    </div>
  );
};
 
export default InvoiceForm;