import React, { useEffect, useState } from 'react';
import { CommonDataTable } from '../../common-components/common-material-react-table/CommonMaterialTable';
import {  CoopSummaryHeaders } from '../../constants/tableConstants/tableHeaders';
import {  useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../redux/store';
import { getCoopSummary} from '../../redux/reducers/CoopSummaryReducer';
import { formatCurrency } from '../../common-components/commonFunctions';


const CoopSummary: React.FC = () => {
    const CoopData=useSelector((state:RootState)=>state.CoopSummaryReducer.CoopData)
    const totalBudget=useSelector((state:RootState)=>state.CoopSummaryReducer.totalBudget)
    const totalRemaining=useSelector((state:RootState)=>state.CoopSummaryReducer.totalRemaining)
    const totalIncurred=useSelector((state:RootState)=>state.CoopSummaryReducer.totalIncurred)
    const dispatch=useDispatch<AppDispatch>()
    useEffect(()=>{
        dispatch(getCoopSummary())
    },[])
 
  return (
    <div className='mx-9 '>
      <p className='text-[18px] font-semibold '>COOP Summary</p>
      <div className=" flex items-center ">
      {/* <div className='w-full gap-4 justify-start font-poppins font-semibold '>
          <div className='w-full'><p className='text-[14px]'><b>Total Budget : </b> <span className='font-normal ' >
          {formatCurrency(totalBudget)}
            </span>   </p></div>
          <div className='w-full'><p className='text-[14px]'><b>Total Remaining : </b> 
           <span  className='font-normal '>
          {formatCurrency(totalRemaining)}
            </span >  </p></div>
          <div className='w-full'><p className='text-[14px]'><b>Total Spent : </b>
           <span  className='font-normal '>
          {formatCurrency(totalIncurred)}
            </span>  </p></div>
        </div>  */}
        <div className='w-[100%] justify-end flex gap-2'>
          {/* <div className='w-full relative flex justify-end '>
            <input
              type="text"
              placeholder="Search Here"
              className="  border border-solid border-neutral-200 font-poppins rounded-lg focus:border-blue-500 focus:outline-none p-2 w-full "
            />
          
          </div>
          <button
            className="bg-red-600 text-white px-4 py-[11px] rounded-md cursor-pointer " 
          >
            Search
          </button> */}
        </div>
      </div>
        {/* <CommonDataTable
          data={CoopData}  
        //   totalDocuments={totalDocuments}
          tableHeaders={CoopSummaryHeaders}
          showRowNumbers
          colSizes={[100, 100,100]}
          callback={() => { }}
        //   nextCallback={handleNextCall}
          pageSize={20}
          enableGlobalFilter={false}
        //   totalPages={totalPages}
        enableBottomToolbar={false}
        changeSelectedColumnDataDesign={[ "Budget","incurred","Remaining" ]}
        changedDataCellColumn={(header, accessor) => {
          if (accessor === "Budget") {
            return {
              accessorKey: accessor,
              header: header,
              Cell: ({ row }) => {
                return(
                <div>
                  {formatCurrency(row.original.Budget)}
                </div>
              )}
          }}else  if (accessor === "incurred") {
            return {
              accessorKey: accessor,
              header: header,
              Cell: ({ row }) => {
                return(
                <div>
                  
                  {formatCurrency(row.original.incurred)}
                </div>
              )}
            }}
            else  if (accessor === "Remaining") {
              return {
                accessorKey: accessor,
                header: header,
                Cell: ({ row }) => {
                  return(
                  <div>
                    
                    {formatCurrency(row.original.Remaining)}
                  </div>
                )}
              }}
          
            return {
              accessorKey: accessor,
              header: header,
            }

          }}
        /> */}
         <div className="overflow-x-auto mb-6 bg-white pt-16 pb-16 rounded-md shadow-md">
  <table className="min-w-full border-collapse border border-neutral-200 text-[12px]  
      "  border={4}>
        <thead>
          <tr className="bg-white font-semibold text-[#474747]  " >
            <td className="border-2 border-solid border-gray-200 p-2 text-center">S/No.</td>
            <td className="border-2 border-solid border-gray-200 p-2 text-center">Account Name</td>
            <td className="border-2 border-solid border-gray-200 p-2 text-center">Account Number</td>
            <td className="border-2 border-solid border-gray-200 p-2 text-center">Budget</td>
            <td className="border-2 border-solid border-gray-200 p-2 text-center">Remaining</td>
            <td className="border-2 border-solid border-gray-200 p-2 text-center">Spent</td>
          </tr>
        
        </thead>
        <tbody>
          {CoopData.map((item,index) => (
            <tr className="bg-white text-[13px]  text-center items-center " >
              <td className="border-2 border-solid border-gray-200 p-2" >{index+1}</td>
              <td className="border-2 border-solid border-gray-200 p-2" >{item.Account_name}</td>
              <td className="border-2 border-solid border-gray-200 p-2" >{item.Account_number}</td>
              <td className="border-2 border-solid border-gray-200 p-2" >{formatCurrency( item.Budget)}</td>
              <td className="border-2 border-solid border-gray-200 p-2">{formatCurrency(item.Remaining)}</td>
              <td className="border-2 border-solid border-gray-200 p-2" >{formatCurrency(item.incurred)}</td>
            </tr>
           
          ))}
        </tbody>
        <tr className='bg-white text-center font-semibold' >
        {/* <td style={{border:"1px solid rgba(232, 237, 234, 1) "}} ></td> */}

        <td className='border-2 border-solid border-gray-200 p-2 ' colSpan={3}>Total</td>
        <td className='border-2 border-solid border-gray-200  ' >  {formatCurrency(totalBudget)} </td>
        <td className='border-2 border-solid border-gray-200  '>{formatCurrency(totalRemaining)}</td>
        <td className='border-2 border-solid border-gray-200  '> {formatCurrency(totalIncurred)}</td>
        </tr>
      </table>
    </div>
    </div>
  );
};

export default CoopSummary;

