import React from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../common-components/Loader';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface HeaderProps {
  title: string;
  onClose?: () => void;
}


const Header: React.FC<HeaderProps> = ({ title,onClose }) => {
  const isLoading=useSelector((state:RootState)=>state.budgetReducer.isLoading)

  return (
    <header className="flex p-3 h-[10%] w-full bg-rose-50  ">
      <div className="flex justify-between w-full ">
        <Loader isLoading={isLoading}>
          <h1 className="text-2xl font-semibold text-neutral-800">
            {title}
          </h1>
        </Loader>
        <button onClick={onClose}
          className="flex gap-1.5 items-center self-stretch px-1 py-0.5 my-auto text-base font-black text-center whitespace-nowrap rounded-md border border-solid
         border-slate-500 border-opacity-20 text-slate-500 mr-10 cursor-pointer  "> 
         <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18 6L6 18M6 6l12 12" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

         
        </button>
      </div>
    </header>
  );
};

export default Header;