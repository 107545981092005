
import { useLocation, useNavigate } from 'react-router-dom';
import Profile from './Profile';
import { navItems } from '../navbar/navbarConstants';
import Notifications from './notification';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { fetchAllUnreadNotifications } from '../../redux/reducers/nootificationReducer';
import { user } from '../../redux/reducers/UserReducer';


interface TopBarProps {
  show?: boolean;
  // toggle?: () => void;
}
const TopBar: React.FC<TopBarProps> = ({ show }) => {
  const[togglebutton,setTogglebutton]=useState<boolean>() 
   const { pathname } = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const userData = useSelector(user);
  // const[show,setShow]=useState<boolean>(true)

  useEffect(() => {
    if(userData?._id) dispatch(fetchAllUnreadNotifications(userData?._id  || "" ));
  },[userData])

  
//   const toggle = () => {
//     const sidebarElement = document.getElementById("sidebar-id");
//     if(sidebarElement?.classList.contains("w-0"))
//       { 
//         sidebarElement?.classList.add("w-full")
//         sidebarElement?.classList.remove("w-0")
//         sidebarElement?.classList.remove("overflow-hidden")
//         // sidebarElement?.classList.add("transition-all")
//       }
//     else if(sidebarElement?.classList.contains("w-full")) {
//       sidebarElement?.classList.add("w-0")
//       sidebarElement?.classList.add("overflow-hidden")
//       sidebarElement?.classList.remove("w-full")
//       // sidebarElement?.classList.add("transition-all")
//     }
// }
 const toggle = () => {
    const sidebarElement = document.getElementById("sidebar-id");
    if (sidebarElement) {
      sidebarElement.classList.toggle("hidden");
      sidebarElement.classList.toggle("block");
      // sidebarElement.classList.toggle("transition")
    }
  };

  return (
    <div className="flex flex-col w-full border-b-2 border-solid border-gray-200 ">
      <div className="flex flex-col justify-center px-4 py-4 md:px-8 md:py-6">
        <div className="flex flex-col md:flex-row gap-5 justify-between items-center">
          <div className="flex items-center w-full justify-between">
            <div className="flex items-center gap-5 md:gap-10 w-full">
              <div className="flex items-center text-sm md:text-sm cursor-pointer gap-2">
                <svg 
                  onClick={toggle}
                  className="w-5 h-5 md:w-6 md:h-6 text-gray-800 dark:text-white  "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeWidth="2"
                    d="M5 7h14M5 12h14M5 17h14"
                  />
                </svg>
              
                {/* <span className="  lg:w-40 md:w-24 ">
                  {navItems.filter(nav => nav.path === pathname)[0]?.label}
                </span> */}
              </div>
              <div className="relative lg:w-[350px] md:w-full md:mr-1">
                {/* <input
                  type="text"
                  placeholder="Search"
                  className="lg:w-full md:text-base px-3 py-2 md:px-4 md:py-3 rounded-lg bg-slate-50
                  "
                />
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/a21faadf7db4840f23b2e78116625d623e8ba7403ba2333ca0a2f9847018278c?apiKey=a88c36c4e69e48eb98243259c870fe00&&apiKey=a88c36c4e69e48eb98243259c870fe00"
                  alt="Search icon"
                  className="object-contain absolute right-0 top-3 md:right-3 md:w-5"
                /> */}
              </div>
            </div>
            <div className="flex gap-2 md:gap-3">
              {/* <div className="flex justify-center items-center w-10 h-10 md:w-12 md:h-12  bg-slate-200 rounded-full">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/25ab0e9627241edc4475d15ab56b26e852aba35c2601109b4a0af8996910c4be?apiKey=a88c36c4e69e48eb98243259c870fe00&&apiKey=a88c36c4e69e48eb98243259c870fe00"
                  alt="Notification icon"
                  className="object-contain w-5 md:w-5"
                />
              </div> */}
                <Notifications />
              <Profile />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
