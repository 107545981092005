import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { API } from '../../constants/API';
import { RootState } from '../store';
import { act } from 'react';

interface InvoiceData{
  data:InvoiceItem[],
  totalDocuments:number;
  totalPages:number;
}

interface optionObj {
  value: string;
  label: string;
}
interface ApproverInvoiceIte{
    id: string;
    file: File | null,
    approvers: optionObj[];
    required_approval:number;
    InvoiceId:string;
    Invoice_number: number | string;
    Invoice_Date: Date;
    Invoice_amount: number | string;
    canApprove:boolean;
    Invoice_status:number;
}

interface InvoiceItem {
  id: string;
  Invoice_number:number|string;
  Invoice_amount:number;
  InvoiceId:number;
  Invoice_Date:Date;
  createdAt:"";
  updateAt:"";
  Invoice_URL:string;
  Invoice_status:number;
  status:number;
  message:string;
  canApprove?:boolean;
  canUpdate?:boolean;
}

interface DataState {
  approverInvoice: ApproverInvoiceIte;
  data: InvoiceItem[];
  isLoading: boolean;
  isError: boolean;
  status: string | number;
  statusCode: number;
  message: string | null;
  isUploadLoading?: boolean; 
  uploadedFile: InvoiceItem;
  matchFile:{
    status:number;
    message:string;
  };
  totalDocuments:number;
  totalPages:number;
  selectedLoading:string;
}
interface PostSearchItem{
  searchTerm?:string;
  search:string|number,

}

const initialState: DataState = {
  data: [],
  isLoading: false,
  isError: false,
  status: '',
  statusCode: 0,
  message: null,
  isUploadLoading: false, 
  uploadedFile: {
    // id: "",
    // Invoice_number:"",
    // Invoice_amount:"",
    // Invoice_URL:"",
    Invoice_status:0,
    canApprove:false
    // InvoiceId:0,
    // status:0,
    // message:""
  } as InvoiceItem,
  matchFile:{
    status:0,
    message:""
  },
  approverInvoice:{
    canApprove:false
  } as ApproverInvoiceIte,
  totalDocuments:0,
  totalPages:0,
  selectedLoading:""
};

export const fetchInvoices = createAsyncThunk(
  'fetchInvoices',
  async (data:{page: number,limit:number} , { rejectWithValue }) => {
    try {
      const response: AxiosResponse<InvoiceData> = await axios.post(API.GETINVOICES,data);
      return response;
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue('error occurred');
    }
  }
);
export const getSelectedInvoice= createAsyncThunk(
  'getSelectedInvoice',
  async (data:any, { rejectWithValue }) => {
    try {
      const response= await axios.get(`${API.GETSELECTEDINVOICE}/${data._id}` );
      return response;
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue('error occurred');
    }
  }
);


export const postUploadInvoices = createAsyncThunk(
  "postUploadInvoice",
  async (data:any, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('invoice', data.file);
      formData.append('invoiceData', JSON.stringify(data));
      const response = await axios.post(API.POSTINVOICES, formData);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);
export const postSearchInvoice=createAsyncThunk(
  "postSearchInvoice", async(data:any,{rejectWithValue})=>{
    try{
      const response=await axios.post(API.POSTSEARCHINVOICE,data)
      // console.log(response,"response")
      return response.data;
    }catch(err:any){
      return rejectWithValue(err.response)
    }
  }
)

export const PostmatchInvoices = createAsyncThunk(
  "PostmatchInvoices",
  async (data:any, { rejectWithValue }) => {
    try {
      // const formData = new FormData();
      // formData.append('invoice', data);
      console.log(data)
      const response = await axios.post(API.POSTSELECTEDINVOICE, data);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

const InvoiceSlice = createSlice({
  name: 'Invoice',
  initialState,
  reducers: {
    emptyApproverInvoice:(state) => {
      state.approverInvoice = initialState.approverInvoice;
    },
    updateUploadLoading: (state) => {
      state.isUploadLoading = !state.isUploadLoading;
    },
    emptyStatusMessage:(state)=>{
      state.message=initialState.message,
      state.statusCode=initialState.statusCode
    },
    emptyMatch:(state) => {
      if(state.matchFile){
        state.matchFile.status = initialState.matchFile.status;
        state.matchFile.message = initialState.matchFile.message;
      }},
      emptySelectedInvoice:(state) => {
        state.isError=initialState.isError
        state.isLoading=initialState.isLoading
        state.isUploadLoading=initialState.isUploadLoading
        // state.matchFile=initialState.matchFile
        state.message=initialState.message
        state.status=initialState.status
        state.statusCode = initialState.statusCode
        state.uploadedFile=initialState.uploadedFile
      },
      updateTotalDocuments:(state)=>{
        state.totalDocuments+=1
      },
      startSelectedLoading:(state,action) => {
        console.log(action.payload)
        state.selectedLoading = action.payload;
      },
      // declineInvoice: (state, action: PayloadAction<{id:number, match:false, Invoice_number: number; Invoice_amount: number }>) => {
      //   const { Invoice_number, Invoice_amount,id,match } = action.payload;
        
      //   console.log('Declining invoice:', Invoice_number, Invoice_amount);
      // },
      // markAsVerified: (state, action: PayloadAction<{id:number, match:true, Invoice_number: number; Invoice_amount: number }>) => {
      //   const { Invoice_number, Invoice_amount } = action.payload;
      //   console.log('Marking invoice as verified:', Invoice_number, Invoice_amount);
      // },
      updateUploadInvoice: (state,action:PayloadAction<{invoiceField:string,value:any}>) => {
        if(action.payload.invoiceField === 'Invoice_number') state.uploadedFile.Invoice_number = action.payload.value;
        if(action.payload.invoiceField === 'Invoice_amount') state.uploadedFile.Invoice_amount = action.payload.value;
        if(action.payload.invoiceField === 'Invoice_Date') state.uploadedFile.Invoice_Date = action.payload.value;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoices.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.status = 'loading';
      })
      .addCase(fetchInvoices.fulfilled, (state, action: PayloadAction<{data:InvoiceData,status:number}>) => {
        state.isLoading = false;
        state.data = action.payload.data.data;
        state.totalDocuments=action.payload.data.totalDocuments;
        state.totalPages=action.payload.data.totalPages
        // console.log(action.payload , "actionnn");
        state.status = 'succeeded';
        state.statusCode = action.payload.status;
        state.message = 'Data fetched successfully';
      })
      .addCase(fetchInvoices.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isError = true;
        state.status = 'failed';
        state.message = action.payload ? action.payload : 'Fetch failed';
      })
     
      .addCase(postUploadInvoices.pending, (state) => {
        state.isUploadLoading = true; 
        state.isError = false;
        state.status = 'uploading';
      })
      .addCase(postUploadInvoices.fulfilled, (state, action: PayloadAction<AxiosResponse<any>>) => {
        state.status = action.payload.status

        if(action.payload.data.canUpdate){
          state.approverInvoice.id = action.payload.data.invoice._id
          state.approverInvoice.InvoiceId = action.payload.data.invoice.InvoiceId
          state.approverInvoice.canApprove = action.payload.data.canUpdate
        }

        state.statusCode = action.payload.status; 
        state.data.unshift(action.payload.data.invoice)
        state.message = action.payload.data.message;
        state.isUploadLoading = false; 
      })
      .addCase(postUploadInvoices.rejected, (state, action: PayloadAction<any>) => {
        state.isUploadLoading = false; 
        state.isError = true;
        state.status = 'upload_failed';
        state.statusCode = action.payload.status; 
        state.message = action.payload ? action.payload.data.message : 'Upload failed';
      })
      .addCase(getSelectedInvoice.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.status = 'loading';
      })
      .addCase(getSelectedInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        if(state.uploadedFile){
          state.uploadedFile=action.payload.data
          state.uploadedFile.status = action.payload.status;
          if(action.payload.data?.message) state.uploadedFile.message = action.payload.data.message;
        }
        state.status = 'succeeded';
        state.statusCode = 200;
        state.message = 'Selected invoice fetched successfully';
      })
      .addCase(getSelectedInvoice.rejected, (state, action:PayloadAction<any>) => {
        state.isLoading = false;
        state.isError = true;
        if(state.uploadedFile){
          state.uploadedFile.status = action.payload.status;
          if(action.payload.data?.message) state.uploadedFile.message = action.payload.data.message;
        }
        state.status = 'failed';
        
      })
      .addCase(PostmatchInvoices.pending, (state,action) => {
        state.isLoading = true;
        state.isError = false;
        state.status = 'loading';
        state.isUploadLoading = true; 
      })
      .addCase(PostmatchInvoices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isUploadLoading = false;

        if(state.matchFile){
          if(state.approverInvoice.canApprove)
            state.data.map((invoice:any,index:number) => {
              if(invoice._id === state.approverInvoice.id)
                state.data[index] = action.payload.data.invoice
            })
          state.uploadedFile = action.payload.data.invoice
          state.matchFile.status = action.payload.status;
          state.matchFile.message = action.payload.data.message;
       } 
        state.status = 'succeeded';
      })
      .addCase(PostmatchInvoices.rejected, (state, action:PayloadAction<any>) => {
        state.isLoading = false;
        state.isUploadLoading = false;
        state.isError = true;
        state.status = 'failed';
        if(state.matchFile){
          state.matchFile.status = action.payload.status;
          state.matchFile.message = action.payload.data.message;
       } 
        
      })
      .addCase(postSearchInvoice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        postSearchInvoice.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.status = action.payload.status
          state.data=action.payload.data
            console.log(action.payload.data,"actionnnnnn")
          state.statusCode = action.payload.status;
          // state.message = "";
        }
      )
      .addCase(postSearchInvoice.rejected, (state, action: PayloadAction<any>) => {
        state.status = "failed";
        state.data=[]
      });
     
      
  },
});

export const { startSelectedLoading, emptyApproverInvoice, updateTotalDocuments, emptySelectedInvoice, emptyMatch, updateUploadLoading,emptyStatusMessage,updateUploadInvoice } = InvoiceSlice.actions;
// export const Account=(state:RootState)=>state.invoiceReducer.data
export const InvoiceData = (state: RootState) => state.invoiceReducer.data;
export default InvoiceSlice.reducer;
