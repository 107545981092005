import React from 'react';

type ApprovalCardProps = {
  bgColor: string;
  title: string;
  count: string|any;
  textColor:string;
  stat?: any;
  formatNumber?:any;
};

const ApprovalCard: React.FC<ApprovalCardProps> = ({ bgColor, title,  textColor,stat,formatNumber ,count}) => {
  return (
    <div className={`flex rounded-2xl sm:mx-1  lg:mx-2 md:mx-0.5 p-3  flex-col flex-1 shrink justify-center items-start  ${bgColor} rounded-sm  `}>
      <div className='pl-2.5'>
        <div className="self-stretch lg:text-[16px]  md:text-sm font-semibold  ">{title}</div>
        <div  title={stat} className={`${textColor} font-semibold self-stretch mt-1 text-[18px] `} >
          {/* {formatNumber(stat)} */}
          {count}
        </div>
      </div>
    </div>
  );
};
export default ApprovalCard;