import React, { ReactNode, useEffect, useState } from 'react';
import { MaterialReactTable, MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { PaginationItem, Tooltip } from '@mui/material';

interface RenderTopToolbarProps {
  table: MRT_TableInstance<any>; // Adjust the type according to your table's data type
}

type RenderTopToolbarFunction = (props: RenderTopToolbarProps) => React.ReactNode;

// Define the interface for table headers
interface TableHeaders {
  [key: string]: string; // Maps column keys to header names
}

// Define the interface for additional columns
interface AddNewCellColumn {
  accessorKey: string;
  header: string;
  Cell?: (props: { row: any; index: number }) => JSX.Element;
}

interface changeColInterface<T> {
  accessorKey: string;
  header: string;
  Cell?: (props: { row: any }) => JSX.Element;
  // other properties
}

type ChangedDataCellColumnFunction = (header: string, accessor: string) => changeColInterface<any>;

// Define the props interface with optional properties
interface CommonDataTableProps {
  data: any[]; // Replace `any` with a more specific type if known
  tableHeaders: TableHeaders;
  element?:ReactNode,
  actionButtons?: boolean; // Optional
  editButton?: boolean; // Optional
  deleteButton?: boolean; // Optional
  callback: (data: any,index:number) => void;
  viewButton?: boolean; // Optional
  inputCells?: boolean; // Optional
  disableFields?: boolean; // Optional
  inputTypeColumnCallback?: (value: string, columnKey: string, index: number) => void;
  addNewCellColumns?: AddNewCellColumn[]; // Optional
  changeSelectedColumnDataDesign?: string[]; // Optional
  changedDataCellColumn?: ChangedDataCellColumnFunction; // Optional
  customActionButton?: (row: any) => JSX.Element; // Optional
  topToolbar?:RenderTopToolbarFunction ,
  muiTableRowProps?:string,
  colSizes?: any[],
  enableTopToolbar ?: boolean
  nextCallback?:(data?:any)=>void;
  selectedIndex?:number | null;
  tableCursor?:string;
  enableGlobalFilter?:boolean;
  pageSize?:number;
  totalPages?:number;
  totalDocuments?:number;
  showRowNumbers?:boolean;
  isLoading?:boolean;
  enableBottomToolbar?:boolean,
  NodataFound?:string
}

interface MyData {
  id: number;
  name: string;
}

interface PaginationState {
  pageIndex: number;
  pageSize: number;
}

export const CommonDataTable: React.FC<CommonDataTableProps> = ({
  data,
  tableHeaders,
  element,
  actionButtons = false, // Default value
  editButton = false, // Default value
  deleteButton = false, // Default value
  callback,
  nextCallback,
  viewButton = false, // Default value
  inputCells = false, // Default value
  disableFields = false, // Default value
  inputTypeColumnCallback = () => {}, // Default empty function
  addNewCellColumns = [], // Default empty array
  changeSelectedColumnDataDesign = [], // Default empty array
  changedDataCellColumn,
  customActionButton,
  topToolbar,
  muiTableRowProps="bg-gray-200",
  colSizes=[],
  enableTopToolbar=false,
  enableGlobalFilter=false,
  selectedIndex,
  tableCursor="",
  pageSize=10,
  totalPages,
  totalDocuments,
  showRowNumbers=false,
  enableBottomToolbar=false,
  isLoading=false,
  NodataFound="No record found",
}) => {
  const dataKeys = Object.keys(tableHeaders);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSize,
  });
  const [page,setPage] = useState(1)

  useEffect(() =>{
    if(data?.length <=0){
      setPagination({
        pageIndex: 0,
        pageSize: pageSize,
      })
      setPage(1)
    }
  },[data])


  // Define table columns based on headers and other props
  let tableColumns: MRT_ColumnDef<any>[] = [];

  // if (showRowNumbers) {
    tableColumns.push({
      header: 'S/No.',  // Column header
      id: 'rowNumber',  // Unique column ID
      size: 50,         // Column width
      Cell: ({ row }) => totalPages ? (row.index + 1) + (page > 1 ? pageSize * (page - 1) : 0 ) : (row.index + 1), // Display row number
    });
  // }

    tableColumns.push(...dataKeys.map((e,index) => {
    if (changeSelectedColumnDataDesign.includes(e) && changedDataCellColumn) {
      return changedDataCellColumn(tableHeaders[e], e);
    }

    const columnDef: MRT_ColumnDef<any> = {
      accessorKey: e,
      header: tableHeaders[e],
      size:colSizes.length ? colSizes[index] : 90,
      Cell: ({row}) => (row.original[e] ? row.original[e] : '-'),
    };

    if (inputCells) {
      columnDef.Cell = ({ row }) => (
        <input
          type={
            typeof row.original[e] === 'string'
              ? 'text'
              : typeof row.original[e] === 'number'
              ? 'number'
              : 'text'
          }
          className="form-control"
          onChange={({ target }) => inputTypeColumnCallback(target.value, e, row.index)}
          disabled={disableFields}
          value={row.original[e]}
          placeholder={tableHeaders[e]}
        />
      );
    }

    return columnDef;
  }));

  if (customActionButton) {
    tableColumns.push({
      id: 'actions',
      header: 'Action',
      Cell: ({row}) => (
        <div className="hstack gap-2 fs-1">
          {customActionButton(row)}
        </div>
      ),
    });
  }
  return (

    <div className="w-full">
      <MaterialReactTable
        columns={tableColumns}
        data={data}
        enableRowNumbers={false}
        enableSorting={false}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableHiding={false}
        enableColumnFilters={false}
        enableColumnActions={false}
        enableGlobalFilter={enableGlobalFilter}
        renderTopToolbar={topToolbar}
        enableTopToolbar={enableTopToolbar}
        muiTableProps={{
          sx: {
            zIndex:0
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            padding:"8px",
            border: '.5px solid rgba(232, 237, 234, 1)',
            fontSize:"13px",
            textAlign:"center",
            
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            padding:"8px",
            border: '.5px solid rgba(232, 237, 234, 1)',
            alignItems:"center",
            justifyContent:"center",
            textAlign:"center"
          },
        }}
        muiTableBodyRowProps={({row}) => {
          return ({
          className: muiTableRowProps, 
          sx:{
            height:"20px",
            backgroundColor:row.index === selectedIndex ? "":"white",
            cursor: tableCursor,
            alignItems:"center",
            justifyContent:"center",
            textAlign:"center",
          },
          onClick:()=>callback(row.original, row.index)
          
        })
        }}
        muiTopToolbarProps={{
          sx:{
            zIndex:0,
          },
         
        }}
        // enableBottomToolbar={true}
        enableBottomToolbar={enableBottomToolbar}
        muiBottomToolbarProps={(data) => {
          return ({
          sx:{
            zIndex:0,
            fontSize:"5px"
          },
        })
        }}
        state={{
          pagination: {
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize,
          },
        }}
        initialState={{
          pagination: {
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize,
          },
        }}
        renderBottomToolbar={({ table }) => {
          return (
          <div className=" bg-white flex justify-end gap-4 py-4  items-center text-[14px]">
            <p>{`${
            totalDocuments ? 
              (pageSize * page) > totalDocuments 
                  ? 
              totalDocuments 
                : 
              (pageSize) * page 
                : 
              page * pageSize > data?.length 
                ? 
              data?.length
                :
              data?.length <= 0 
                ? 
                0
                :
              page*pageSize
              } / ${totalDocuments ? totalDocuments : data?.length }`}</p>
              <Stack spacing={2} >
                <Pagination 
                  renderItem={(item) => {
                    let tooltipTitle = '';
                    switch (item.type) {
                      case 'first':
                        tooltipTitle = 'First Page';
                        break;
                      case 'previous':
                        tooltipTitle = 'Previous Page';
                        break;
                      case 'next':
                        tooltipTitle = 'Next Page';
                        break;
                      case 'last':
                        tooltipTitle = 'Last Page';
                        break;
                      default:
                        tooltipTitle = '';
                    }
              
                    return (
                      <Tooltip title={tooltipTitle}>
                        <PaginationItem {...item} />
                      </Tooltip>
                    );
                  }}
                  onChange={(event,value) => {
                    setPage(value)
                    if(!totalPages) setPagination(old => ({pageIndex:value-1,pageSize:old.pageSize}))
                    nextCallback && nextCallback(value)  
                  }} count={totalPages ? totalPages:table.getPageCount() } showFirstButton showLastButton page={page} />
              </Stack>
          </div>
        )}}
        renderEmptyRowsFallback={({ table }) => (<div className=' text-gray-400 m-3 text-[14px] flex items-center justify-center'>{NodataFound} </div>)}
      />
    </div>
  );
};
