import { changeStatus, fetchApprovalDeclinedData } from '../../redux/reducers/ApprovedDeclined';

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { approvedAndDeclinedComment, selectedBudgetId, selectedBudgetRow, status,UpdatedBudgetStatus } from "../../redux/reducers/budgetreqReducer";
import { AppDispatch, RootState } from "../../redux/store";
import Popup from './Popup';
import { user } from '../../redux/reducers/UserReducer';
import { FaRegUser } from 'react-icons/fa';
import { userProfile } from '../../redux/reducers/profileReducer';
import { Userdata } from '../../constants/tableConstants/dashboardTableHeaders';



interface ApprovalInfoProps {
    approverName?: string;
    approverImageSrc?: string;
    comments?: string;
    onClose: () => void;
   icon?:any
  }
  interface ApprovalData {
    comments?: string;
    // Include any other fields that might be needed
  }
const ApprovalInfo: React.FC<ApprovalInfoProps> = ({ approverName, approverImageSrc, comments,onClose }) => {
  const [popupType, setPopupType] = useState<'Approve' | 'Decline' | null>(null);
  const currentStatus = useSelector(status);
  const budgetuserData = useSelector((state: RootState) => state.ApprovedDeclined.budgetuserData);
  const userData = useSelector(user)
  const dispatch = useDispatch<AppDispatch>();
  const budgetId = useSelector(selectedBudgetId);
  const selectedBudget = useSelector(selectedBudgetRow)  // selected budget data
  const comment = useSelector((state: RootState) => approvedAndDeclinedComment(state));

  const handleApproveClick = () => {
    setPopupType('Approve');
    dispatch(changeStatus(1))
  };

  const handleDeclineClick = () => {
    setPopupType('Decline');
    dispatch(changeStatus(2))
  };

  const handleClosePopup = () => {
    setPopupType(null);
  };


  const handleForm = (data: ApprovalData) => {
    const approvalData = {
      budgetId: budgetId,
      approve_status: budgetuserData.approve_status,
      comment:data.comments,
      user_id: userData?._id,
    };
    dispatch(fetchApprovalDeclinedData(approvalData));
    
    onClose()
    // updating selected budget req status 

  };

  const isApprover = userData?.role?.includes('approver') && selectedBudget?.budget?.canApprove

  return (
    <div className="flex flex-col w-full max-w-4xl mx-auto ">
      { currentStatus === 1 || currentStatus === 2? (
        <div
          className={`flex flex-col border border-solid ${
            currentStatus === 1 ? 'border-green-500' : currentStatus===2 ?'border-red-600'  : ""
          } py-3 px-4 mt-6 text-base rounded-md bg-opacity-10 ${
            currentStatus === 1 ? 'bg-green-500' : currentStatus===2 ?'bg-red-600'  : ""
          }`}
        >
          <div className="flex flex-wrap gap-6 justify-between items-center">
            <div className={`text-lg font-medium ${
             currentStatus === 1 ? 'text-green' : currentStatus===2 ?'border-red-600'  : ""
            }`}>
              {currentStatus === 1 ? 'Approved By' : 'Declined By'}
            </div>
            <div className="flex items-center gap-2">
              {/* <img
                loading="lazy"
                src={approverImageSrc}
                alt={`${approverName}'s profile`}
                className="object-cover w-12 h-12 rounded-full"
              /> */}
              <FaRegUser className='w-6 h-6 border-2 border-solid rounded-full'/> 
              <div className="text-lg font-medium">{selectedBudget?.budget?.approverName || ""}</div>
            </div>
          </div>
          <div className="flex flex-col mt-4">
            <label htmlFor="approver-comments" className="text-sm font-medium text-neutral-800">
              Approver's Comments
            </label>
            <div className="relative mt-2.5
             bg-white border border-neutral-200 rounded-md p-3 text-neutral-500  text-[14px]  ">
              <div className="w-full max-h-[80px] overflow-y-auto text-justify ">{comment}</div>
            </div>
          </div>
        </div>
      ) : isApprover ?  (
        <div className="flex flex-col mt-12">
          <div className="flex justify-center gap-4">
            <button
              className="bg-transparent cursor-pointer
           font-poppins border border-black  text-[15px] text-gray-500 w-1/2 h-12 rounded-md shadow-none"
              onClick={handleDeclineClick}
            >
              Decline
            </button>
            <button
              className="bg-green-600 cursor-pointer text-white text-[15px]
               font-bold w-1/2 h-12 rounded-md font-poppins"
              onClick={handleApproveClick}
            >
              Approve
            </button>
          </div>
        </div>
      ): ""}

      {popupType && (
        <Popup
          type={popupType}
          onClose={handleClosePopup}
          onSubmit={handleForm}
        />
      )}
    </div>
  );
};

export default ApprovalInfo;