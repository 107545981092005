// import React from 'react';

// interface AmountFieldProps {
//   amount:  number | any;
//   inputClassName?:string;
//   className?:string;
// }

// const AmountField: React.FC<AmountFieldProps> = ({ amount ,inputClassName,className}) => {
//   return (
//     <div className={`flex flex-col ${className} flex-1 shrink w-full basis-0  `}>
//       <label htmlFor="amount" className="self-start font-semibold leading-none text-neutral-800">
//         Amount
//       </label>
//       <div className={`flex rounded-md border border-solid ${inputClassName} bg-zinc-100 border-neutral-200 relative flex-col mt-2 text-black font-bold leading-none max-md:max-w-full`}>

//         <div className=" mx-2 my-4  bottom-[18px]">
//           {amount}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AmountField;
import React, { useEffect, useRef } from 'react';

interface AmountFieldProps {
  amount: number | string;
  inputClassName?: string;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  required?: boolean;
  name: string;
  ref?: React.Ref<HTMLInputElement>;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void; // Update to KeyboardEvent
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void; // Update to FocusEvent
  amountInputShow:boolean
}

const AmountField: React.FC<AmountFieldProps> = ({
  amount,
  name,
  inputClassName,
  className,
  onChange,
  disabled,
  required = false,
  ref,
  onKeyDown,
  onBlur,
  amountInputShow
}) => {

  const amountRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if(amountInputShow)
      amountRef.current?.focus();
  },[amountInputShow])
  return (
    <div className={`flex flex-col ${className} flex-1 shrink w-full basis-0`}>
      <label htmlFor="amount" className="self-start font-semibold leading-none text-neutral-800">
        Amount
        {required && <span className="text-red-600 mx-1">*</span>}
      </label>
      <input
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        name={name}
        ref={amountRef}
        value={amount}
        disabled={disabled}
        onChange={onChange}
        className={`flex rounded-md border border-solid ${inputClassName} border-neutral-300 mt-2 text-black font-bold leading-none p-4`}
      />
    </div>
  );
};

export default AmountField;
