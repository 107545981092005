import React, { useRef, useState } from "react";
import Header from "./Header";
import ApprovalStatus from "./ApprovalStatus";
import InputField from "./InputField";
import TextArea from "./TextArea";
import ApprovalInfo from "./ApprovalInfo";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { status } from "../../redux/reducers/budgetreqReducer";
import moment from "moment";
import {
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import Loader from "../../common-components/Loader";
import { formatCurrency } from "../../common-components/commonFunctions";
import { FaUpload } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";

interface MyComponentProps {
  isOpen: boolean;
  onClose: () => void;
}


const UploadInvoices: React.FC<MyComponentProps> = ({ isOpen, onClose }) => {
  
  if (!isOpen) return null;

  const [isPopupOpen, setIsPopupOpen] = useState(false);
    const isLoading=useSelector((state:RootState)=>state.budgetReducer.isLoading)
  // console.log(isLoading,"isloading")
  const handleToggle = () => {
    setIsPopupOpen(true);
  };


  const selectedBudget = useSelector(
    (state: RootState) => state.budgetReducer.selectedBudgetRow
  );

  const currentStatus = useSelector(status);
  const statusText =
    currentStatus === 1 ? "Approved" : currentStatus === 2 ? "Declined" : "";
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.files);
  };
  const handleSVGClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <> 
    {!isLoading &&
      // ?  (
      //                       <div role="status" className="flex justify-center items-center "><svg aria-hidden="true" className="w-6 h-6 text-gray-200 animate-spin px-1 dark:text-gray-600 fill-black flex items-center justify-center " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/> </svg></div> ):
         <div className="fixed inset-0 z-20 flex items-center justify-end">
        <div className="bg-white z-10 w-[700px] h-screen overflow-hidden flex flex-col">
          <main className="flex flex-col flex-grow overflow-y-auto overflow-x-hidden">
            <section className="flex flex-col flex-grow ">
              <Header
                title={
                  currentStatus === 1
                    ? "Approved Request"
                    : currentStatus === 0
                    ? "New Request"
                    : "Declined Request"
                }
                onClose={onClose}
              />
              {/* <button
          className=""
          onClick={onClose}
        >bu</button> */}
                <Loader isLoading={isLoading} >
              <div className="p-6 w-full">
                <div className="  flex flex-col flex-grow  ">
               
                 
                  <div className="flex  flex-col md:flex-row sm:flex-row xs:flex-row ">
                    {currentStatus === 1 ? (
                      <div
                        className={`w-full ${currentStatus === 1 ? "" : ""}`}
                      >
                        <ApprovalStatus statusOfForm={statusText} />
                      </div>
                    ) : currentStatus === 2 ? (
                      <div className="w-full">
                        <ApprovalStatus statusOfForm={statusText} />
                      </div>
                    ) : currentStatus === 0 ? (
                      <div className="p-0 m-0"></div>
                    ) : (
                      ""
                    )}

                  {/* {currentStatus === 1 && (
                      <div className="w-1/5 pl-2">
                        <button
                          className="w-[215px]  text-[15px] h-[40px] cursor-pointer border border-rose-100 bg-transparent text-black rounded font-bold font-poppins "
                          onClick={handleToggle}
                        >
                          Attach Invoices
                        </button>
                      </div>
                    )}  */}
                  </div>
                  <div className="flex gap-6 items-start w-full text-base leading-none flex-wrap">
                    <InputField
                      label="Account Name"
                      value={selectedBudget?.budget?.account_name ?? 0}
                    />
                    <InputField
                      label="Account Number"
                      value={selectedBudget?.budget?.account_number ?? 0}
                    />
                  </div>
                  <div className="flex gap-6 items-start  w-full text-base leading-none flex-wrap">
                    <InputField
                      label="COOP Type"
                      value={selectedBudget?.budget?.coopType ?? 0}
                    />
                    <InputField
                      label="Claim Number"
                      value={selectedBudget?.budget?.claim_number?.length ? selectedBudget?.budget?.claim_number : "N/A"}
                    />
                  </div>
                  <div className="flex gap-6 items-start mt-6 w-full text-base leading-none flex-wrap">
                    <div className="flex flex-col text-[14px] flex-1 shrink w-full basis-0 min-w-[240px] max-md:max-w-full overflow-hidden">
                      <label className="self-start text-[14px] font-semibold leading-none text-neutral-800">
                        Selected ISBN
                      </label>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        className="mt-2.5 font-14-imp text-neutral-500 rounded-md border border-solid bg-zinc-100 border-neutral-200"
                        multiple
                        value={selectedBudget?.budget?.isbn}
                        renderValue={(selected) =>
                          selected
                            .map(
                              (item, index) =>
                                `${item}${
                                  index < selected.length - 1 ? "," : ""
                                }`
                            )
                            .join(" ")
                        }
                      >
                        <div className="max-h-[200px] text-[14px]">
                          {selectedBudget?.budget?.isbn.map((isbn) => (
                            <MenuItem key={isbn} value={isbn}>
                              <Checkbox checked={!!isbn} />
                              <ListItemText primary={isbn} />
                            </MenuItem>
                          ))}
                        </div>
                      </Select>
                    </div>
                  </div>

                  <div className="flex gap-6 items-start mt-6 w-full leading-none flex-wrap">
                    <div className="flex flex-col flex-1 shrink w-full  overflow-hidden">
                      <label className="self-start text-[14px] font-semibold leading-none text-neutral-800">
                        Selected Title
                      </label>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        className="mt-2.5 font-14-imp text-neutral-500 rounded-md border border-solid bg-zinc-100 border-neutral-200  "
                        multiple
                        value={selectedBudget?.budget?.title}
                        renderValue={(selected) =>
                          selected
                            .map(
                              (item, index) =>
                                `${item}${
                                  index < selected.length - 1 ? "," : ""
                                }`
                            )
                            .join(" ")
                        }
                      >
                        <div className="max-h-[200px]  ">
                          {selectedBudget?.budget?.title.map((title) => (
                            <MenuItem key={title} value={title} className="">
                              <Checkbox checked={!!title} className="" />
                              <ListItemText
                                primary={title}
                                className=" max-w-10 "
                              />
                            </MenuItem>
                          ))}
                        </div>
                      </Select>
                    </div>
                  </div>
                  <div className="flex gap-6 items-start  w-full leading-none flex-wrap">
                    <InputField
                      label="Request Created Date"
                      value={
                        moment(selectedBudget?.budget?.createdAt).format(
                          "MM-DD-YYYY"
                        ) ?? 0
                      }
                    />
                    <InputField
                      label="Request Approved Date"
                      value={
                        selectedBudget?.budget?.status === 1 ? moment(selectedBudget?.budget?.updatedAt).format(
                          "MM-DD-YYYY"
                        ) : "N/A"
                      }
                    />
                  </div>
                  <div className="">
                    <InputField
                      label="Approval By(Deadline)"
                      value={
                        selectedBudget?.budget?.deadline ?
                        moment(selectedBudget?.budget?.deadline).format(
                          "MM-DD-YYYY"
                        ) : "N/A"
                      }
                    />
                  </div>
                  <div className="w-full">
                    <TextArea
                      label="Description"
                      value={selectedBudget?.budget?.description?.length ? selectedBudget?.budget?.description : "N/A"}
                    />
                    <TextArea
                      label="Notes"
                      value={selectedBudget?.budget?.notes?.length ? selectedBudget?.budget?.notes : "N/A"}
                    />
                  </div>
                  <div className="flex gap-6 items-center w-full whitespace-nowrap ">
                    <InputField
                      label="Requested Amount"
                      value={`${
                        selectedBudget?.budget?.required_amount_budget ? formatCurrency(selectedBudget?.budget?.required_amount_budget) : 0
                      }`}
                      isLarge
                      className={"mb-2"}
                    />
                  </div>
                  <ApprovalInfo
                    onClose={onClose}
                    approverName="John Dee"
                    icon={ <IoIosArrowDown />}
                    // approverImageSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/d7ed4be7d19454579b3192cd68edfa7710098bb61c66eecfd2c6ffb1c03297ca?apiKey=a88c36c4e69e48eb98243259c870fe00&&apiKey=a88c36c4e69e48eb98243259c870fe00"
                    comments="
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada. Nullam ac dui vitae velit feugiat convallis vel id dui."
                  />
                </div>
              </div>
                  </Loader>

            </section>
            
          </main>
        </div>
        <div
          className="fixed inset-0 bg-black opacity-50"
          // onClick={onClose}
        ></div>
      </div>
 }
    
      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end items-center z-20 ">
          <div className="bg-rose-100">
            <div className="  bg-white rounded-lg w-[300px] md:w-[700px] sm:w-[500px] xs:w[400px]  h-screen  ">
              <div className="flex justify-center bg-rose-100 mb-10">
                <h2 className="text-[25px] ml-5  bg-rose-100 w-full rounded-md">
                  Upload Invoice
                </h2>
                <button
                  onClick={onClose}
                  
                  className="flex gap-1.5 items-center self-stretch px-1 py-0.5 my-auto text-base font-black text-center whitespace-nowrap rounded-md border border-solid
         border-slate-500 border-opacity-20 text-slate-500 mr-10 cursor-pointer  "
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 6L6 18M6 6l12 12"
                      stroke="#808080"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <form className="m-6">
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  accept="audio/mp3"
                />
                <div className="w-full border-2 h-[90px] border-gray-300 border-dashed rounded-md font-poppins custom-placeholder flex justify-center items-center ">
                <div  className='mb-8'>
                <FaUpload/>
                </div>
                <div className=" absolute text-neutral-500 text-center ">Upload Here</div>
                  <div className="mt-[50px]">
                   
                  </div>
                </div>
                <div className="  flex justify-between gap-3 mt-6 ">
                  <button
                    type="button"
                    className="bg-red-600 font-bold cursor-pointer text-[15px] font-poppins  text-white w-full h-12 rounded-md"
                    onClick={onClose}
                  >
                    Upload
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UploadInvoices;

