import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdCalendarMonth } from "react-icons/md";

interface DatePickerProps {
  label: string;
  placeholder: string;
  value: Date | null;
  onChange?: (date: Date | null) => void;
  required?: boolean;
  min?: Date;
  disabled?: boolean;
  labelClassname?:string,
  inputClassname?:string,
  iconClassname?:string,
  invoiceStatus?:number
}

const DatePickerComponent: React.FC<DatePickerProps> = ({
  label,
  placeholder,
  value,
  onChange,
  required,
  min,
  disabled,
  labelClassname,
  inputClassname,
  iconClassname,
  invoiceStatus
}) => {
  const [calendarOpen, setCalendarOpen] = useState(false);

  const handleClick = () => {
    if(!disabled) setCalendarOpen(!calendarOpen);
  };

  return (
    <div className="flex w-full flex-col flex-1 shrink basis-0 border-neutral-400">
      <label
        className={`text-sm leading-none font-bold ${labelClassname} `}
      >
        {label}
        {required && <span className="text-red-600 mx-1">*</span>}
      </label>
      <div className="flex relative flex-col mt-2 w-full text-base leading-none text-slate-500">
        <div className="flex  flex-col  w-full" 
            onClick={handleClick}
        >
          <input
            type="text"
            className={` ${inputClassname} p-2 border border-solid text-gray-500 text-[14px] border-neutral-300  font-poppins rounded-md`}
            placeholder={placeholder}
            value={value ? moment(value).format("MM-DD-YYYY") : invoiceStatus ? invoiceStatus === 0  && value ? moment(value).format("MM-DD-YYYY") : "N/A" : ""}
            readOnly
            onKeyDown={(event) => event.key === "Backspace" && onChange && onChange(null)}
            disabled={disabled}
          />
          <div
            className={`flex absolute z-0 mt-2 ${iconClassname} items-center self-start right-[11px] cursor-pointer`}
          >
            <MdCalendarMonth />
            
          </div>
        </div>
          {calendarOpen && (
            <div className="absolute z-10 top-10">
              <DatePicker
                minDate={min}
                selected={value}
                onChange={(date: Date | null) => {
                  if (onChange) {
                    onChange(date);
                  }
                  setCalendarOpen(false);
                }}
                onClickOutside={() => setCalendarOpen(false)}
                inline
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default DatePickerComponent;
