import React, { useEffect, useState } from 'react';
import { CommonDataTable } from '../../common-components/common-material-react-table/CommonMaterialTable';
import { ActionScreenHeader } from '../../constants/tableConstants/tableHeaders';
import { useDispatch, useSelector } from 'react-redux';
import { adjustPageAndLimit, emptyStatus, postAmountDetail, selectData } from '../../redux/reducers/AccountDetailReducer';
import { AppDispatch, RootState } from '../../redux/store';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { MdCancel } from 'react-icons/md';
import { formatCurrency } from '../../common-components/commonFunctions';

const AccountDetails: React.FC = () => {
  const data = useSelector((state: RootState) => selectData(state));
  const [searchTerm, setSearchTerm] = useState<string>('');
  const statusCode = useSelector((state:RootState) => state.amountReducer.statusCode)
  const totalPages = useSelector((state:RootState) => state.amountReducer.totalPages)
  const totalDocuments = useSelector((state:RootState) => state.amountReducer.totalDocuments)
  const limit =useSelector((state:RootState)=>state.amountReducer.limit)

  const account_name =useSelector((state:RootState)=>state.amountReducer.account_name)
  const account_number =useSelector((state:RootState)=>state.amountReducer.account_number)

  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();

  const message = useSelector((state:RootState) => state.amountReducer.message);

  const handleNextCall=(currentPage:number)=>{
    if(params.acc_num) dispatch(postAmountDetail({account_id: params.acc_num , page:currentPage, limit:limit, searchTerm:searchTerm}))
  }

  useEffect(() => {
    if(statusCode === 200) {
      dispatch(adjustPageAndLimit())
      dispatch(emptyStatus())
    }
  },[statusCode])

  useEffect(() => {
    if(params.acc_num && !searchTerm?.length) dispatch(postAmountDetail({account_id: params.acc_num , page:1, limit:limit, searchTerm:searchTerm}))
  },[params.acc_num, searchTerm])

  const handleSearch = () => {
    const trimmedSearchTerm = searchTerm.trim();
    setSearchTerm(trimmedSearchTerm)
    // console.log("Search Term:", trimmedSearchTerm);
    if(params.acc_num) dispatch(postAmountDetail({account_id: params.acc_num , page:1, limit:limit, searchTerm: trimmedSearchTerm,}))
  }
  return (
    <div className=''>
      <div className="mb-4 flex flex-col md:flex-row items-center justify-end">
        {/* {account_number?.length ?  */}
        <div className='w-full gap-4 justify-start'>
          <div className='w-full'><p className='text-[14px]'><b>Account Name:</b>  {account_number} </p></div>
          <div className='w-full'><p className='text-[14px]'><b>Account Number:</b>  {account_name} </p></div>
        </div> 
        {/* : ""} */}
        <div className='w-[100%] justify-end flex gap-6'>
          <div className='w-full relative'>
            <input
              type="text"
              onKeyDown={(event) => {
                if(event.key === "Enter")
                  if(params.acc_num) dispatch(postAmountDetail({account_id: params.acc_num , page:1, limit:limit, searchTerm:searchTerm}))

              }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search Claim Number, ISBN, Title, Co-Op Year"
              className="border border-solid border-neutral-200 font-poppins rounded-lg w-full focus:border-blue-500 focus:outline-none p-2  "
            />
            {searchTerm?.length ?<div title="Clear All" onClick={() =>{
                setSearchTerm("")
                if(params.acc_num) dispatch(postAmountDetail({account_id: params.acc_num , page:1, limit:limit, searchTerm:""}))
              }} 
              className="flex absolute items-center right-[-10px] top-2.5 ">
              <MdCancel className="text-red-600 w-5 h-5 cursor-pointer"/>
              {/* <ConfirmDialog /> */}
            </div>:""}
          </div>
          <button
            className="bg-red-600 text-white px-4 py-[11px] rounded-md cursor-pointer "
            onClick={handleSearch}  
          >
            Search
          </button>
        </div>
      </div>
        <CommonDataTable
          data={data}  
          totalDocuments={totalDocuments}
          tableHeaders={ActionScreenHeader}
          showRowNumbers
          NodataFound={"No data found"}
          enableBottomToolbar={true}
          colSizes={[100, 100]}
          callback={() => { }}
          nextCallback={handleNextCall}
          pageSize={10}
          changeSelectedColumnDataDesign={["credit_date","event_date","dollar_amount"]}
          changedDataCellColumn={(header, accessor) => {
            if (accessor === "credit_date") {
              return {
                accessorKey: accessor,
                header: header,
                Cell: ({ row }) => {
                  return(
                  <div>
                    {moment(row.original.credit_date).format("MM DD YYYY")}
                  </div>
                )}
            }}else  if (accessor === "dollar_amount") {
              return {
                accessorKey: accessor,
                header: header,
                Cell: ({ row }) => {
                  return(
                  <div>
                    {row.original.dollar_amount && row.original.dollar_amount['$numberDecimal'] ? formatCurrency(Number(row.original.dollar_amount['$numberDecimal'])) : formatCurrency(Number(row.original.dollar_amount))}
                    {row.original.doller_amount && formatCurrency(Number(row.original.doller_amount['$numberDecimal']))}
                  </div>
                )}
              }}
            
              return {
                accessorKey: accessor,
                header: header,
              }

            }}
          enableGlobalFilter={false}
          totalPages={totalPages}
        />
    </div>
  );
};

export default AccountDetails;

