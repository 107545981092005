import React from 'react'

import {Dropdown} from "../dropdown/DropDown"
import { Link, useNavigate } from 'react-router-dom'
import { FaBell, FaRegBellSlash, FaRegUser } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../redux/store'
import { IoIosArrowForward } from 'react-icons/io'
import { updateNotificationsStatus,updateNotificationReadStatus } from '../../redux/reducers/nootificationReducer'

const Notifications = () => {
    const dispatch=useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const notifications = useSelector((state:RootState) => state.notificationReducer.notifications)

    const Notifications = notifications.filter(e => e.isread_status === false)
    const Count = Notifications.length;
    const displayCount = Count > 9 ? '9+' :Count;

  return (
    <div className='z-10'>
                        <Dropdown.container
                  id="notifications"
                  icon={
                    <div className="flex relative justify-center items-center w-10 h-10 md:w-12 md:h-12 bg-slate-200 rounded-full">
                      <FaBell />
                      {notifications.filter(e => e.isread_status === false).length ? <div className='text-xs w-6 h-6 absolute shadow-lg overflow-hidden flex items-center justify-center rounded-full bg-green-700 text-white top-[-5px] right-[-5px]'>
                        {/* <span>
                          {notifications.filter(e => e.isread_status === false).length}
                          </span> */}
                           <span>{displayCount}</span>
                        </div> : ""}
                    </div>
                  }
                  className="w-[400px] right-[-26px] top-9 text-sm rounded-lg shadow-3xl "
                  innerContainerClassName=""
                >
                  <div className="skdhskdh">
                  <div className='p-[7px] border-b-[1px] border-solid border-gray-300 flex flex-col'>
                  {
                    notifications?.length ? (
                    <div className='w-full flex justify-between'>
                      {/* <small>Top 10 notifications below</small> */}
                      <small>All notifications below</small>
                      <small className='cursor-pointer' onClick={() => {
                          dispatch(updateNotificationsStatus(notifications.map(notification => notification._id)))
                          dispatch(updateNotificationReadStatus(notifications.map(notification => notification._id)))
                        }}>Mark All Read</small>
                    </div>   
                    ) :
                    <div className='flex flex-col'>
                      No Notifications Right Now
                      {/* <small>Your top 10 notifications will show up here</small> */}
                      <small>Your all notifications will show up here</small>
                      </div>
                  }
                  </div>
                  <Dropdown.item className={`items-center overflow-y-auto max-h-[450px] h-auto grid grid-flow-row ${notifications?.length ? "justify-start": "justify-center" } border-solid border-b-[1px] border-gray-200  `}>
                  {
                    notifications ?.length ? notifications.map((notification,index:number) => (
                        <div className={`flex gap-3 items-center hover:bg-gray-200 p-2 m-0 rounded ${notifications?.length ? "justify-start": "justify-center" }`} onClick={() => {
                            dispatch(updateNotificationsStatus([notification._id]))
                            dispatch(updateNotificationReadStatus([notification._id]))
                          }}>
                          <div className={`${notifications?.length ? "justify-start": "justify-center" } flex items-center`}>
                            <FaRegUser className='h-[20px] w-[20px] rounded-full border-[1px] border-solid border-gray-300'/> 
                          </div>
                          <div className=" flex text-slate-700">{notification.notification_message}</div>
                        </div>
                    ))
                    : 
                    (
                    <div className="flex gap-3 items-center w-full justify-center">
                        <div className=" w-full flex text-slate-700 items-center "><FaRegBellSlash className='text-[#ccc] h-[60px] w-[54px]'/>
                        </div>
                    </div>
                  )
                  }
                    </Dropdown.item>
                    </div>

                    {/* <Dropdown.item className="items-center justify-center border-t-[1px] border-solid border-gray-300 bg-gray-200" onClick={() => {navigate("/")}}>
                        <div className="flex gap-3 items-center justify-center" >
                            <div className=" flex items-center justify-center text-slate-700">View All Notifications 
                            <IoIosArrowForward />
                            </div>
                        </div>
                    </Dropdown.item> */}

                    {/* <Dropdown.item className="items-center justify-center">
                        <div className="flex gap-3 items-center justify-center" onClick={() => {navigate("/")}}>
                            <div className='bg-black h-[20px]'></div>
                            <FaBell />
                            <div className=" flex text-slate-700">Recent notifications</div>
                        </div>
                    </Dropdown.item>

                    <Dropdown.item className="items-center justify-center">
                        <div className="flex gap-3 items-center justify-center">
                            <FaBell />
                            <Link to={"notifications"}>View all notifications</Link>
                        </div>
                    </Dropdown.item> */}
                </Dropdown.container> 
    </div>
  )
}

export default Notifications