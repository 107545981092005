import StatCard from "./StatCard";
import ApprovalCard from "./ApprovalCard";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import BudgetSummary from '../../assets/images/budgetSummary.png'
import OverView from '../../assets/images/Overview.png'
import { formatCurrency } from "../../common-components/commonFunctions";

const MainContent = () => {
  const dashboard = useSelector((state: RootState) => state.dashBoardReducer.dashboard);
  

    return (
      <section  >
        <div className="flex-col text-zinc-950 ">
          <h2
            className="flex flex-col self-stretch 
            font-semibold  text-lg whitespace-nowrap text-center md:text-left sm:text-left xs:text-left "
          >
            Overview
          </h2>
          <div className="flex flex-col xs:flex-row sm:flex-row md:flex-row   w-full  lg:gap-0  md:gap-2 ">
          <div className="flex items-center justify-center mb-2  md:mb-0 lg:w-[25%] shadow-lg rounded-sm md:w-[150px]">
            <StatCard
              src={OverView}
              alt="Total Budget"
              title="Total Budget"
              // formatNumber={formatNumber}
              stat={dashboard?.totalBudget ? formatCurrency(dashboard?.totalBudget) : '$0.00'}
            
            />
          </div>
          <div className="flex mb-2 md:mb-0 md:mx-0 sm:mx-0 flex-col lg:ml-4 md:ml-0 sm:ml-2 ml-2 bg-white grow justify-center whitespace-nowrap rounded-lg shadow-lg ">
            <div className="flex gap-2 items-center self-start lg:text-lg md:text-sm font-semibold ml-2 ">
              <img
                loading="lazy"
                src={BudgetSummary}
                alt="Approvals"
                className="object-contain shrink-0 self-stretch w-[15px]   lg:w-[21px] md:w-[18px] ml-4 sm:w-[15px]  "
              />
              <div className="">Budget Summary</div>
            </div>
                      <div className="flex flex-wrap mb-4 ml-5 mr-5 md:flex-row ">
                      
            <ApprovalCard
              bgColor="bg-amber-50"
              textColor="text-amber-400"
              title="Remaining Amount"
              count={dashboard?.totalRemaining ? formatCurrency(dashboard?.totalRemaining) : '$0.00'}
              // formatNumber={formatNumber}
            />
         
              <ApprovalCard
                bgColor="bg-green-100"
                textColor="text-green-600"
                title="Spent Amount"
                count={dashboard?.totalIncurred ? formatCurrency(dashboard?.totalIncurred) : "$0.00"}
                // formatNumber={formatNumber}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainContent;
