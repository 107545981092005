import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { AppDispatch, RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

interface FormData {
  comments: string;
}

const Popup: React.FC<{ type: 'Approve' | 'Decline'; onClose: () => void; onSubmit: (data: FormData) => void; }> = ({ type, onClose, onSubmit }) => {
  const { register, handleSubmit, formState: { errors } } = useForm<FormData>();

  const onFormSubmit: SubmitHandler<FormData> = (data) => {
    console.log(data)
    onSubmit(data);
    
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end items-center">
      <div className='bg-rose-100'>
        <div className="bg-white rounded-lg w-[400px] md:w-[700px] sm:w-[600px] h-screen">
          <div className='flex bg-rose-100'>
          <h2 className="text-[20px] md:text-[25px] sm:text-[25px] font-bold ml-5 bg-rose-100 w-full rounded-md">
            {type === 'Approve' ? 'Add Comments To Approve' : 'Give reason for Decline'}
          </h2>
          <button onClick={onClose}
          className="flex gap-1.5 items-center self-stretch px-1 py-0.5 my-auto text-base font-black text-center whitespace-nowrap rounded-md border border-solid
         border-slate-500 border-opacity-20 text-slate-500 mr-10 cursor-pointer  "> 
         <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18 6L6 18M6 6l12 12" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        </button>
          </div>
          <form onSubmit={handleSubmit(onFormSubmit)} className='m-7 '>
            <div className='relative'>
              <label htmlFor="comments" className="block text-[14px] md:text-[18px] font-medium mb-2">
                Add Comments:
              </label>
              <textarea
                placeholder='Write Here...'
                id="comments"
                {...register("comments", { required: "This field is required" })}
                rows={7}
                className="w-full border border-gray-300 p-2 rounded-md font-poppins mb-5"
              ></textarea>
              {errors.comments && <p className="text-red-500 text-sm absolute bottom-[-10px] left-2">{errors?.comments.message}</p>}
            </div>
            <div className="  flex justify-between gap-3   ">
              <button
                type="button"
                className="bg-transparent cursor-pointer text-[15px] font-poppins border border-grey-1 text-gray-500 w-[50%] h-12 rounded-md"
                onClick={onClose}
              >
                Back
              </button>
              <button
                type="submit"
                className={`${type === 'Approve' ? 'bg-green-600' : 'bg-red-700'} font-poppins cursor-pointer text-white text-[15px] px-4 py-2 rounded-md flex-1`}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Popup;
