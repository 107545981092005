import { createBrowserRouter } from "react-router-dom";
import Dashboard from "../components/dashboard/Dashboard";
import AdminLogin from "../components/auth/index";
import ForgotPassword from "../components/auth/forgotPassword/ForgotPassword";
import Layout from "./layout";
import NewBudgetRequest from '../components/newbudetrequest/BudgetRequest'
import BudgetRequest from "../components/budgetRequest/BudgetRequest";
import Invoices from "../components/invoices/Invoices";
import UploadInvoices from "../components/invoices/UploadInvoices";
import ProtectedRoute from "./ProtectedRoute";
import UserManage from "../components/UserManagement/UserManage";
import UserDetails from "../components/UserManagement/UserDetails";
import ResetPasswordForm from '../components/auth/Reset/ResetPasswordForm'
import ResetPasswordPage from "../components/auth/Reset/Resetpassword";
import OnBoardingPage from "../components/auth/onBoarding/Onboarding";
import InvoiceForm from '../components/invoices/InvoiceForm'
import ActionScreen from "../components/dashboard/AccountDetails";
import ViewDetails from "../components/dashboard/ViewDetails";
import Landing from '../components/Landing/Landing'
import CoopSummary from "../components/dashboard/CoopSummary";



export const routes = createBrowserRouter([
    {
        path:"/",
        element:<Layout />,
        // loader:,
        children:[
            {
                path:"",
                element:<ProtectedRoute element={<Dashboard />} />
                // loader:,
            },
            {
                path:"/CoopSummary",
                element:<ProtectedRoute element={<CoopSummary />} />
            },
            {
                path:"newBudgetReq",
                element:<ProtectedRoute element={<NewBudgetRequest />} />
                // loader:,
            },
            {
                path:"/login",
                element:<AdminLogin />,
                // loader:,
            },
            {
                path:"/landing",
                element:<Landing />,
                // loader:,
            },
           
            {
                path:"budgetReq",
                element:<ProtectedRoute element={<BudgetRequest />} />
                // loader:,
            },
            {
                path:"forgot-password",
                element:<ForgotPassword />,
                // loader:,
            },
             {
                path:"onBoarding/:onBoardingToken",
                element:<OnBoardingPage/>,
                // loader:,
            },
             {
                path:"reset-password/:id",
                element:<ResetPasswordPage/>,
                // loader:,
            },
            {
                path:"invoices",
                element:<ProtectedRoute element={<Invoices />} />
                // loader:,
            },
            // {
            //     path:"UploadInvoices",
            //     element:<ProtectedRoute element={<UploadInvoices/>} />
            // },
            {
                path:"/VerifyInvoices/:id",
                element: <ProtectedRoute element={<InvoiceForm/>}/>
            },
            {
                path:"usermanage",
                element:<ProtectedRoute element={<UserDetails/>} />
            },
            {
                path:"viewDetails/:acc_num",
                element:<ProtectedRoute element={<ViewDetails/>} />
            },
        ]
    },
])